import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import Brand from 'components/brandListItem';
import { hasAccount, FEATURE_FAVORITES } from 'util/APIUtils';
import { addToCart as addToCartAction } from 'modules/cart';
import { selectUser } from 'selectors/user';
import { selectBrands } from 'selectors/brand';
import { selectCart } from 'selectors/cart';

import ClaimNotice from './ClaimNotice';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const BrandResult = memo(({ brand, reloadDisplayedBrands }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const cart = useSelector(selectCart);
  const addToCart = (...args) => dispatch(addToCartAction(...args));
  const [claimDetailsOpen, setClaimDetailsOpen] = useState(false);

  const toggleClaimDetails = () => {
    setClaimDetailsOpen(!claimDetailsOpen);
  };

  const showDetails = () => {
    history.push(`/brands/${brand.id}`);
  };

  return (
    <Brand
      key={brand.id}
      isSearch={true}
      user={user}
      brand={brand}
      cart={cart}
      addToCart={addToCart}
      reloadDisplayedBrands={reloadDisplayedBrands}
      displayClaimBrand={claimDetailsOpen}
      ownersMessage={<ClaimNotice />}
    >
      {!brand.ownedByLoggedInUser ?
        (
          <>
            {hasAccount(user) &&
              (
                <>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={toggleClaimDetails}
                    className="brand-result-my-brands"
                  >
                    My Brand
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: '8px' }}
                    onClick={showDetails}
                    className="brand-result-details"
                  >
                    Details
                  </Button>
                </>
              )
            }
            {FEATURE_FAVORITES &&
              (
                <Button
                  size="small"
                  variant="contained"
                  className={clsx({
                    [classes.button]: true,
                    'brand-result-favorites': true,
                  })}
                >
                  Add to Favorites &nbsp;
                </Button>
              )
            }
          </>
        ) : (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            style={{ marginTop: '8px' }}
            onClick={showDetails}
            className="brand-result-details"
          >
            Details
          </Button>
        )
      }
    </Brand>
  );
});

BrandResult.propTypes = {
  brand: PropTypes.object.isRequired,
  reloadDisplayedBrands: PropTypes.func.isRequired,
};

const BrandResults = ({ reloadDisplayedBrands }) => {
  const brands = useSelector(selectBrands);

  return (
    <>
      {brands.map((brand) => {
        return <BrandResult key={brand.id} brand={brand} reloadDisplayedBrands={reloadDisplayedBrands} />;
      })}
    </>
  );
};

export default memo(BrandResults);
