import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  news: {
    margin: '0 0 25px',
  },
  text: {
    textAlign: 'center',
    margin: '0 0 15px',
  }
}));

export default function News() {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.news}>
        <strong>News!</strong> We now have self printable Brand Certificates. <Link to="/my-brands">Look in My Brands</Link>
      </Typography>
      <Typography className={classes.text}>Let's start by finding your Brand!</Typography>
    </div>
  );
};
