import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  makeStyles,
  Paper,
  IconButton,
  Tabs,
  Tab,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: '500px',
    width: '100%',
    margin: '0 auto',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: '#FAFAFB',
    '& h5': {
      margin: '0',
      flex: '1',
      textAlign: 'left',
    },
    '& svg': {
      fontSize: '16px',
    },
  },
  tabs: {
    backgroundColor: '#FAFAFB',
    minHeight: '0',
    padding: '0 10px',
  },
  tab: {
    fontSize: '12px',
    minHeight: '0',
    padding: '5px 15px',
    minWidth: 'auto',
  },
  body: {
    position: 'relative',
    padding: '15px',
  },
  uploadArea: {
    display: 'block',
    textAlign: 'center',
    cursor: 'pointer',
    border: '2px dashed #cccccc',
    padding: '10px',
    height: '80px',
    margin: 'auto',
    lineHeight: 'calc(80px - 20px)',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '14px',
    fontStyle: 'italic',
    '& input[type="file"]': {
      display: 'none',
    },
  },
  uploadAreaActive: {
    backgroundColor: '#f1f1f1',
    borderColor: theme.custom.primary,
  },
  urlInput: {
    width: '100%',
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '7px 14px',
      fontSize: '14px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      fontSize: '14px',
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: '#ffffff',
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  previewWrapper: {
    maxWidth: '1000px',
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    padding: '10px',
    '& h4': {
      margin: '0 0 10px',
      padding: '0 10px',
    },
  },
  preview: {
    display: 'block',
    maxWidth: '1000px',
    maxHeight: '1000px',
    margin: '0 auto 15px',
  },
  previewClose: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    '& svg': {
      fontSize: '16px',
    },
  },
}));

const SearchByImage = ({ onSearch, onClose }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [activeDragOver, setActiveDragOver] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [url, setUrl] = useState('');

  const handleDragOver = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActiveDragOver(true);
  };

  const handleDragLeave = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActiveDragOver(false);
  };

  const handleDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const dt = ev.dataTransfer;

    setFile(dt.files[0]);
    setActiveDragOver(false);
  };

  const handleFileChange = (ev) => {
    const files = ev.target.files;
    const newFile = files[0];

    setFile(newFile);
    onSearch(newFile);
  };

  const handleUrlChange = (ev) => {
    setUrl(ev.target.value);
  };

  const searchImageUrl = () => {
    if (url) {
      setPreview(url);
    }
  };

  const handleTabChange = (event, index) => {
    setTab(index);
  };

  const reset = () => {
    setFile(null);
    setPreview(null);
    setUrl('');
  };

  const processImage = useCallback(() => {
    if (file) {
      const reader = new FileReader();

      reader.onloadend = (ev) => {
        setPreview(ev.target.result);
      };
      reader.readAsDataURL(file);
    }

    return null;
  }, [file]);

  const renderTypes = () => {
    if (tab === 0) {
      return (
        <label
          className={clsx({
            [classes.uploadArea]: true,
            [classes.uploadAreaActive]: activeDragOver,
          })}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            onChange={handleFileChange}
          />
          <span>Drag'n'drop image, or click to select files</span>
        </label>
      );
    } else {
      return (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <TextField
              id="imageUrl"
              label="Image URL"
              variant="outlined"
              size="small"
              className={classes.urlInput}
              onChange={handleUrlChange}
              value={url}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={searchImageUrl}
            >
              Search by URL
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  useEffect(() => {
    processImage();
  }, [processImage]);


  if (preview) {
    return (
      <Paper className={classes.previewWrapper} elevation={2}>
        <h4>Image Search</h4>
        <img src={preview} alt="Search" className={classes.preview} />
        <IconButton size="small" className={classes.previewClose} onClick={reset}>
          <CloseIcon />
        </IconButton>
      </Paper>
    );
  }

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.title}>
        <h5>Search Brands with image</h5>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab label="Upload an image" className={classes.tab} />
        <Tab label="Paste image URL" className={classes.tab} />
      </Tabs>
      <div className={classes.body}>
        {renderTypes()}
      </div>
    </Paper>
  );
};

SearchByImage.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SearchByImage;
