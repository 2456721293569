export const PLACEMENT_OPTIONS = ['cattleOrHorse', 'sheep', 'ears', 'sheepFace'];

export const BRAND_DESCRIPTOR = {
  description: { type: 'string', required: true, message: 'Description is required' },
  county: { type: 'string', required: true, message: 'County is required' },
};

export const OWNER_DESCRIPTOR = {
  firstName: [
    { type: 'string', required: true, message: 'First name is required' },
    { type: 'string', required: true, min: 2, message: 'Minimum of 2 characters' },
  ],
  lastName: [
    { type: 'string', required: true, message: 'Last name is required' },
    { type: 'string', required: true, min: 2, message: 'Minimum of 2 characters' },
  ],
  phoneType: { type: 'string', required: true, message: 'Phone type is required' },
  phoneNumber: { type: 'string', required: true, message: 'Invalid phone number', pattern: /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/ },
  email: { type: 'email', required: true, message: 'Email is required' },
  lineOne: { type: 'string', required: true, message: 'Line one is required' },
  city: { type: 'string', required: true, message: 'City is required' },
  state: { type: 'string', required: true, message: 'State is required' },
  zipcode: [
    { type: 'string', required: true, message: 'Must only contain numbers', pattern: /^\d+$/ },
    { type: 'string', required: true, max: 10, message: 'Maximum of 10 digits' },
    { type: 'string', required: true, min: 5, message: 'Minimum of 5 digits' },
  ],
  county: { type: 'string', required: true, message: 'County is required' },
  mailingLineOne: { type: 'string', required: true, message: 'Line one is required' },
  mailingCity: { type: 'string', required: true, message: 'City is required' },
  mailingState: { type: 'string', required: true, message: 'State is required' },
  mailingZipcode: [
    { type: 'string', required: true, message: 'Must only contain numbers', pattern: /^\d+$/ },
    { type: 'string', required: true, max: 10, message: 'Maximum of 10 digits' },
    { type: 'string', required: true, min: 5, message: 'Minimum of 5 digits' },
  ],
  mailingCounty: { type: 'string', required: true, message: 'County is required' },
};

export const ADMIN_OWNER_DESCRIPTOR = Object.keys(OWNER_DESCRIPTOR).reduce((object, key) => {
  if(key !== 'email') {
    object[key] = OWNER_DESCRIPTOR[key]
  }
  return object;
}, {})

export const ADDRESS_DESCRIPTOR = {
  lineOne: { type: 'string', required: true, message: 'Line one is required' },
  city: { type: 'string', required: true, message: 'City is required' },
  state: { type: 'string', required: true, message: 'State is required' },
  zipcode: [
    { type: 'string', required: true, message: 'Must only contain numbers', pattern: /^\d+$/ },
    { type: 'string', required: true, max: 10, message: 'Maximum of 10 digits' },
    { type: 'string', required: true, min: 5, message: 'Minimum of 5 digits' },
  ],
  county: { type: 'string', required: true, message: 'County is required' },
};

export const imageTypes = {
  NEW: 'new',
  EXISTING: 'existing',
  UPLOADED: 'uploaded',
};
