import React from 'react';

export default function Address({ address, displayCounty }) {
  return (
    address &&
    <div>
      {address.lineOne && <div>{address.lineOne}</div>}
      {address.lineTwo && <div>{address.lineTwo}</div>}
      {address.city}, {address.state} &nbsp;{address.zipcode}
      {displayCounty && address.county && <div>In {address.county} County</div>}
    </div>
  );
}