import axios from 'axios';
import { tokenUtil } from '@acclaim/udaf-kit';
import { API_BASE_URL } from '../common/constants';

export default axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export function createAuthClient() {
  const authApi = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000,
    headers: {
      'Authorization': `Bearer ${tokenUtil.getToken()}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  authApi.interceptors.response.use(null, (err) => {
    // TODO: we can apply unauthenticated interceptor here
    // const { response } = err;
    // console.error(response);
    return Promise.reject(err);
  });

  return authApi;
}
