import { createSelector } from "reselect";

const selectUserDomain = (state) => state.get("user");

export const selectUser = createSelector(selectUserDomain, (state) => {
  const user = state.getIn(["data", "user"]);

  if (user) {
    return user.toJS();
  }
  return null;
});

export const selectIsUserAdmin = createSelector(selectUserDomain, (state) =>
  state.getIn(["ui", "isAdmin"])
);

// BEGIN -- Admin Services
export const selectIsUserAdminEdit = createSelector(selectUserDomain, (state) =>
  state.getIn(["ui", "isAdminEdit"])
);
// END -- Admin Services

export const selectIsUserInspector = createSelector(selectUserDomain, (state) =>
  state.getIn(["ui", "isInspector"])
);

export const selectIsUserBetaTester = createSelector(
  selectUserDomain,
  (state) => state.getIn(["ui", "isBetaTester"])
);

export const selectUserPhones = createSelector(selectUserDomain, (state) =>
  state.getIn(["data", "phones"]).toJS()
);

export const selectUserPhonesPending = createSelector(
  selectUserDomain,
  (state) => state.getIn(["ui", "phonesPending"])
);

export const selectUserAddresses = createSelector(selectUserDomain, (state) =>
  state.getIn(["data", "addresses"]).toJS()
);

export const selectUserAddressesPending = createSelector(
  selectUserDomain,
  (state) => state.getIn(["ui", "addressesPending"])
);

export const selectUserOrders = createSelector(selectUserDomain, (state) =>
  state.getIn(["data", "orders"]).toJS()
);

export const selectSelectedOrder = createSelector(selectUserDomain, (state) => {
  const id = state.getIn(["ui", "selectedOrder"]);

  if (id) {
    return state
      .getIn(["data", "orders"])
      .toJS()
      .find((order) => order.id === id);
  }

  return id;
});
