import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import { useRouteMatch } from 'react-router-dom';

import StateSeal from 'img/seal-of-utah.svg';
import UdafLogo from 'img/udaf-logo.png';
import LoadingIndicator from 'components/LoadingIndicator';
import { getWalletCertificate } from 'util/APIUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '314px',
    backgroundColor: '#fff',
    margin: '0 0 0 7px',
  },
  header: {
    width: 80,
    height: 158,
  },
  logo: {
    width: 50,
    display: 'block',
    margin: '12px 0 10px 0',
  },
  stateSeal: {
    width: 50,
  },
  qrContainer: {
    margin: 'auto',
  },
  qrCode: {
    width: 100,
  },
  certificateTitle: {
    fontFamily: ['Garamond', 'Georgia', 'serif'],
    fontStyle: 'italic',
    fontSize: '1em',
    textAlign: 'center',
  },
  ownership: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: 0,
    fontSize: '.6em',
  },
  ownersName: {
    fontSize: '1.2em',
    margin: 0,
  },
  brand: {
    width: 100,
    textAlign: 'center',
    margin: 'auto',
    padding: 0,
  },
  position: {
    fontSize: '1.4em',
    margin: 0,
  },
  brandNumber: {
    fontSize: '1.3em',
  },
  expiresOn: {
    fontFamily: ['Garamond', 'Georgia', 'serif'],
    fontSize: '.7em',
    textAlign: 'center',
    margin: 0,
  },
  border: {
    padding: 0,
  },
  thinBorder: {
    border: 'solid 1px #000',
    padding: '1px',
  },
  thinBorderFour: {
    border: 'dashed 1px #999',
    borderRadius: '5%',
    width: '312px',
    padding: '10px',
    backgroundColor: '#fff',
  },
  innerInnerBorder: {
    border: `dotted 3px ${theme.custom.secondary}`,
  },
  innerInnerInnerBorder: {
    border: `dotted 3px ${theme.custom.secondary}`,
  },
  innerInnerSmallBorder: {
    border: `dotted 2px ${theme.custom.secondary}`,
    padding: '2px',
  },
  innerMostThinBorderTwo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    border: 'solid 1px #999',
    padding: '0 8px 8px',
    height: 158,
    backgroundColor: '#fff',
  },
  brandImage: {
    width: 50,
    height: 50,
  }
}));

const WalletCard = ({ code, owner: brandOwner }) => {
  const match = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const lookupCode = code || (match && match.params && match.params.lookupCode);
  const classes = useStyles();
  const [certificate, setCertificate] = useState(null);
  const [owner, setOwner] = useState(brandOwner);

  useEffect(() => {
    if (lookupCode) {
      getWalletCertificate(lookupCode)
        .then(foundCertificate => {
          setCertificate(foundCertificate);
          setOwner(foundCertificate.brandOwner);
        }).catch(error => {
          enqueueSnackbar((error && error.message) || 'Oops! Something went wrong. Please try again!', { variant: 'error' });
        });
    } else {
      setOwner(brandOwner);
    }
  }, [lookupCode, brandOwner, enqueueSnackbar]);

  if (!certificate) {
    return <LoadingIndicator />;
  }

  return (
    <div className={classes.thinBorderFour}>
      <div className={classes.thinBorder}>
        <div className={classes.innerInnerBorder}>
          <div className={classes.innerInnerInnerBorder}>
            <div className={classes.innerInnerSmallBorder}>
              <div className={classes.innerInnerSmallInnerBorder}>
                <div className={classes.innerMostThinBorder}>
                  <div className={classes.innerMostThinBorderTwo}>
                    <div className={classes.header}>
                      <img src={UdafLogo} alt="Utah Department of Agriculture and Food" className={classes.logo} />
                      <img src={StateSeal} alt="State of Utah Seal" className={classes.stateSeal} />
                    </div>
                    <div className={classes.ownership}>
                      <div className={classes.brand}>
                        <h1 className={classes.certificateTitle}>Certified Owner</h1>
                        {owner.businessName && <h2 className={classes.ownersName}>{owner.businessName}</h2>}
                        {owner && <h2 className={classes.ownersName}>{owner.firstName} {owner.lastName}</h2>}
                        {certificate.brandImage && <img src={certificate.brandImage.imageUrl} className={classes.brandImage} alt={certificate.brandImage.description} />}
                        <Typography className={classes.brandNumber}>
                          {certificate.brandNumber} &nbsp; {certificate.position}
                        </Typography>
                        <h5 className={classes.position}>{certificate.positionDescription}</h5>
                      </div>
                    </div>
                    <div className={classes.qrContainer}>
                      <img src={certificate.code.qrImage} alt="Validate Certificate with this QR Code" className={classes.qrCode} />
                      <h3 className={classes.expiresOn}>Expires on</h3>
                      <h3 className={classes.expiresOn}>{certificate.expirationDate}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletCard;
