import { fromJS } from 'immutable';
import { handleActions, createAction } from 'redux-actions';

const SET_ERROR_MESSAGE = 'app/Auth/SET_ERROR_MESSAGE';
const SET_CORRECT_PROVIDER = 'app/Auth/SET_CORRECT_PROVIDER';
const SET_INSTRUCTION = 'app/Auth/SET_INSTRUCTION';
const RESET_STATE = 'app/Auth/RESET_STATE';

export const authType = {
  LOGIN: 'login',
  SIGNUP: 'signup',
};

const initialState = fromJS({
  error: '',
  provider: '',
  instruction: '',
});

const reducer = handleActions({
  [SET_ERROR_MESSAGE]: (state, { payload: { error } }) => {
    return state.set('error', error);
  },
  [SET_CORRECT_PROVIDER]: (state, { payload: { provider } }) => {
    return state.set('provider', provider);
  },
  [SET_INSTRUCTION]: (state, { payload: { instruction } }) => {
    return state.set('instruction', instruction);
  },
  [RESET_STATE]: () => initialState,
}, initialState);

export const setErrorMessage = createAction(SET_ERROR_MESSAGE, (error) => ({ error }));
export const setCorrectProvider = createAction(SET_CORRECT_PROVIDER, (provider) => ({ provider }));
export const setInstruction = createAction(SET_INSTRUCTION, (instruction) => ({ instruction }));
export const resetState = createAction(RESET_STATE);

export default reducer;
