import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  custom: {
    primary: '#27783F',
    secondary: '#C0C64B',
    danger: '#f50057',
    white: '#ffffff',
    gray100: '#f1f1f1',
    gray200: '#dedede',
    gray300: '#cccccc',
    gray400: '#aaaaaa',
    gray500: '#666666',
    success: '#27783F',
    blue: '#2098f3',
    teal: '#63c2ce',
    lightblue: '#40a9ff',
  },
  typography: {
    fontFamily: '"Avenir Book", Helvetica, Arial, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#C0C64B',
          fontFamily: '"Avenir Book", Helvetica, Arial, sans-serif',
        },
      },
    },
  },
});
