import React from "react";
// import Privacy from './Privacy';
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 640,
    margin: "auto",
    // padding: '0',
  },
  disclaimer: {
    fontSize: "1em",
    color: "red",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.disclaimer}>
        <strong></strong>
      </Typography>
      {/* <Privacy /> */}
    </div>
  );
}
