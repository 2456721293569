import { fromJS } from 'immutable';
import { handleActions, createAction } from 'redux-actions';
import { push } from 'connected-react-router/immutable';
import { getCart } from 'modules/cart';
import { setRegistrationSession } from 'containers/brandRegistration/util';
import { imageTypes } from 'containers/brandRegistration/constants';

const SET_IMAGE_TYPE = 'app/brandRegistration/SET_IMAGE_TYPE';
const SET_OWNER_INFO = 'app/brandRegistration/SET_OWNER_INFO';
const SET_OWNERS_ORDER = 'app/brandRegistration/SET_OWNERS_ORDER';
const SET_EARMARKS = 'app/brandRegistration/SET_EARMARKS';
const SET_EARMARKS_ANIMAL_SELECTION = 'app/brandRegistration/SET_EARMARKS_ANIMAL_SELECTION';
const ADD_OWNER = 'app/brandRegistration/ADD_OWNER';
const REMOVE_OWNER = 'app/brandRegistration/REMOVE_OWNER';
const RESET_STATE = 'app/brandRegistration/RESET_STATE';
const SET_LOADING_INDICATOR = 'app/brandRegistration/SET_LOADING_INDICATOR';
const GET_REGISTRATION_SUCCESS = 'app/brandRegistration/GET_REGISTRATION_SUCCESS';
const GET_REGISTRATION_DONE = 'app/brandRegistration/GET_REGISTRATION_DONE';
const GET_IMAGE_REGISTERED_POSITIONS_SUCCESS = 'app/brandRegistration/GET_IMAGE_REGISTERED_POSITIONS_SUCCESS';
const CREATE_POSITION_SUCCESS = 'app/brandRegistration/CREATE_POSITION_SUCCESS';
const UPDATE_ADDRESS_SUCCESS = 'app/brandRegistration/UPDATE_ADDRESS_SUCCESS';
const UPDATE_REGISTRATION_SUCCESS = 'app/brandRegistration/UPDATE_REGISTRATION_SUCCESS';
const UPDATE_IMAGE_SUCCESS = 'app/brandRegistration/UPDATE_IMAGE_SUCCESS';
const DELETE_POSITION_SUCCESS = 'app/brandRegistration/DELETE_POSITION_SUCCESS';
const SET_ERROR_MESSAGE = 'app/brandRegistration/SET_ERROR_MESSAGE';

const OWNER_SCHEMA = {
  businessName: '',
  firstName: '',
  lastName: '',
  phoneType: 'mobile',
  phoneNumber: '',
  email: '',
  lineOne: '',
  lineTwo: '',
  city: '',
  state: 'UT',
  zipcode: '',
  county: '',
  mailingLineOne: '',
  mailingLineTwo: '',
  mailingCity: '',
  mailingState: 'UT',
  mailingZipcode: '',
  mailingCounty: '',
  isSameAddresses: true,
};

const initialState = fromJS({
  imageType: imageTypes.EXISTING,
  earMarks: {
    animals: [],
    sketch: null,
  },
  owners: [{ ...OWNER_SCHEMA, ownerNumber: 1, sortId: 1, childId: new Date().getTime() }],
  pending: true, // for page initial load
  loading: false, // dynamic loader
  error: '',
  registration: {},
  imageRegisteredPositions: [],
});

const reducer = handleActions({
  [SET_IMAGE_TYPE]: (state, { payload: { type } }) => {
    return state
      .set('imageType', type)
      .set('drawnBrand', null);
  },
  [SET_OWNER_INFO]: (state, { payload: { info, index } }) => {
    return state.updateIn(['owners'], (owners) => {
      return owners.update(index, () => {
        return fromJS(info);
      });
    });
  },
  [SET_OWNERS_ORDER]: (state, { payload: { owners } }) => {
    return state.set('owners', fromJS(owners));
  },
  [ADD_OWNER]: (state) => {
    return state.updateIn(['owners'], (owners) => {
      return owners
        .push(fromJS({
          ...OWNER_SCHEMA,
          ownerNumber: owners.size + 1,
          sortId: owners.size + 1,
          childId: new Date().getTime(),
        }));
    });
  },
  [REMOVE_OWNER]: (state, { payload: { index } }) => {
    return state
      .update('owners', (owners) => {
        return owners
          .remove(index);
      });
  },
  [SET_EARMARKS]: (state, { payload: { sketch } }) => {
    return state.setIn(['earMarks', 'sketch'], sketch);
  },
  [SET_EARMARKS_ANIMAL_SELECTION]: (state, { payload: { animals } }) => {
    return state.setIn(['earMarks', 'animals'], fromJS(animals || []));
  },
  [GET_REGISTRATION_SUCCESS]: (state, { payload: { registration } }) => {
    return state.set('registration', fromJS(registration));
  },
  [GET_REGISTRATION_DONE]: (state) => {
    return state.set('pending', false);
  },
  [GET_IMAGE_REGISTERED_POSITIONS_SUCCESS]: (state, { payload: { registeredPositions } }) => {
    return state.set('imageRegisteredPositions', fromJS(registeredPositions));
  },
  [CREATE_POSITION_SUCCESS]: (state, { payload: { position } }) => {
    return state.updateIn(['registration', 'positions'], (positions) => {
      if (positions) {
        return positions.push(position);
      }

      return fromJS([position]);
    });
  },
  [UPDATE_ADDRESS_SUCCESS]: (state, { payload: { address } }) => {
    return state.update('registration', (registration) => {
      return registration.merge(address);
    });
  },
  [UPDATE_REGISTRATION_SUCCESS]: (state, { payload: { description } }) => {
    return state.update('registration', (registration) => {
      return registration.merge(description);
    });
  },
  [UPDATE_IMAGE_SUCCESS]: (state, { payload: { image } }) => {
    return state.setIn(['registration', 'brandImage'], fromJS(image));
  },
  [DELETE_POSITION_SUCCESS]: (state, { payload: { position } }) => {
    return state.updateIn(['registration', 'positions'], (positions) => {
      return positions.filter((pos) => pos !== position);
    });
  },
  [SET_LOADING_INDICATOR]: (state, { payload: { loading } }) => {
    return state.set('loading', loading);
  },
  [SET_ERROR_MESSAGE]: (state, { payload: { error } }) => {
    return state.set('error', error);
  },
  [RESET_STATE]: () => initialState,
}, initialState);


export const setImageType = createAction(SET_IMAGE_TYPE, (type) => ({ type }));
export const setOwnerInfo = createAction(SET_OWNER_INFO, (info, index) => ({ info, index }));
export const setOwnersOrder = createAction(SET_OWNERS_ORDER, (owners) => {
  return {
    owners: owners.map((o) => {
      const isSameAddresses = (
        o.lineOne === o.mailingLineOne &&
        o.lineTwo === o.mailingLineTwo &&
        o.city === o.mailingCity &&
        o.state === o.mailingState &&
        o.zipcode === o.mailingZipcode &&
        o.county === o.mailingCounty
      );

      return { ...o, isSameAddresses  };
    }),
  };
});
export const setEarMarks = createAction(SET_EARMARKS, (sketch) => ({ sketch }));
export const setEarMarksAnimalSelection = createAction(SET_EARMARKS_ANIMAL_SELECTION, (animals) => ({ animals }));
export const addOwner = createAction(ADD_OWNER);
export const removeOwner = createAction(REMOVE_OWNER, (index) => ({ index }));
export const getRegistrationSuccess = createAction(GET_REGISTRATION_SUCCESS, (registration) => ({ registration }));
export const getRegistrationDone = createAction(GET_REGISTRATION_DONE);
export const getImageRegisteredPositionsSuccess = createAction(GET_IMAGE_REGISTERED_POSITIONS_SUCCESS, (registeredPositions) => ({ registeredPositions }));
export const createPositionSuccess = createAction(CREATE_POSITION_SUCCESS, (position) => ({ position }));
export const updateAddressSuccess = createAction(UPDATE_ADDRESS_SUCCESS, (address) => ({ address }));
export const updateRegistrationSuccess = createAction(UPDATE_REGISTRATION_SUCCESS, (description) => ({ description }));
export const updateImageSuccess = createAction(UPDATE_IMAGE_SUCCESS, (image) => ({ image }));
export const deletePositionSuccess = createAction(DELETE_POSITION_SUCCESS, (position) => ({ position }));
export const setLoadingIndicator = createAction(SET_LOADING_INDICATOR, (loading) => ({ loading }));
export const setErrorMessage = createAction(SET_ERROR_MESSAGE, (error) => ({ error }));
export const resetState = createAction(RESET_STATE);

// Registration methods

/*
  GET Registration
  @id: String
*/
export const getRegistration = (id) => {
  return async function (dispatch, getState, { createAuthClient }) {
    if (id) {
      const client = createAuthClient();
      const state = getState();
      const userId = state.getIn(['user', 'data', 'user', 'id']);

      try {
        const { data } = await client.get(`/users/${userId}/registrations/${id}`);

        await dispatch(getCart());

        if (data.brandImage) {
          await dispatch(getImageRegisteredPositions(data.brandImage.id));
        }

        const owners = [...data.brandOwners].map((owner) => {
          return { ...owner, sortId: owner.id, childId: owner.id };
        });

        if (owners.length > 0) {
          dispatch(setOwnersOrder(owners));
        }

        dispatch(getRegistrationSuccess(data));
        dispatch(getRegistrationDone());
      } catch (err) {
        dispatch(getRegistrationDone());
        dispatch(push('/brands/new'));
      }
    } else {
      dispatch(getRegistrationDone());
      dispatch(push('/brands/new'));
    }
  }
};

export const getImageRegisteredPositions = (imageId) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();

    try {
      const response = await client.get(`/images/${imageId}/positions`);

      dispatch(getImageRegisteredPositionsSuccess(response.data));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
    }
  };
};

/*
  POST Registration
  @formData: { county: String, description: String }
*/
export const createRegistration = (formData, image) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);

    dispatch(setLoadingIndicator(true));

    try {
      const { data } = await client.post(
        `/users/${userId}/registrations`,
        formData,
      );

      await dispatch(createBrandImage(image, data.id));
      await dispatch(getRegistration(data.id));

      // @TEMP ajmiclat: set registration id in session storage
      setRegistrationSession(data.id);
      dispatch(setLoadingIndicator(false));

      dispatch(push('/brands/new/positions'));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
      dispatch(setLoadingIndicator(false));
    }
  };
};

/*
  POST Registration w/ new custom image
  @formData: { county: String, description: String }
*/
export const createRegistrationAndUploadImage = (formData, blob) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);

    dispatch(setLoadingIndicator(true));

    try {
      const { data } = await client.post(
        `/users/${userId}/registrations`,
        formData,
      );

      await dispatch(uploadBrandImage(blob, data.id));
      await dispatch(getRegistration(data.id));

      // @TEMP ajmiclat: set registration id in session storage
      setRegistrationSession(data.id);
      dispatch(setLoadingIndicator(false));

      dispatch(push('/brands/new/positions'));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
      dispatch(setLoadingIndicator(false));
    }
  };
};

/*
  PUT Registration
*/
export const updateRegistration = (formData, image) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);
    const registrationId = state.getIn(['brandRegistration', 'registration', 'id']);
    const currentImageId = state.getIn(['brandRegistration', 'brandImage', 'id']);

    dispatch(setLoadingIndicator(true));

    try {
      await client.put(
        `/users/${userId}/registrations/${registrationId}`,
        formData,
      );

      dispatch(updateRegistrationSuccess(formData));

      if (currentImageId !== image.id) {
        await dispatch(updateBrandImage(image));
      }
      await dispatch(getRegistration(registrationId));

      dispatch(setLoadingIndicator(false));
      dispatch(push('/brands/new/positions'));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
      dispatch(setLoadingIndicator(false));
    }
  };
};

/*
  PUT Registration w/ new custom image
*/
export const updateRegistrationAndUploadImage = (formData, blob) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);
    const registrationId = state.getIn(['brandRegistration', 'registration', 'id']);

    dispatch(setLoadingIndicator(true));

    try {
      await client.put(
        `/users/${userId}/registrations/${registrationId}`,
        formData,
      );

      dispatch(updateRegistrationSuccess(formData));
      await dispatch(uploadBrandImage(blob));
      await dispatch(getRegistration(registrationId));

      dispatch(setLoadingIndicator(false));
      dispatch(push('/brands/new/positions'));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
      dispatch(setLoadingIndicator(false));
    }
  };
};

// Image methods

/*
  POST Images
  @image: Image Object
  @registrationId: string UUID
*/
export const createBrandImage = (image, registrationId) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);

    try {
      await client.post(
        `/users/${userId}/registrations/${registrationId}/images`,
        image,
      );
    } catch (err) {
      throw new Error(err);
    }
  };
};

/*
  PUT Images
  @image: Image Object
*/
export const updateBrandImage = (image) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);
    const registrationId = state.getIn(['brandRegistration', 'registration', 'id']);

    try {
      await client.put(
        `/users/${userId}/registrations/${registrationId}/images`,
        image,
      );
    } catch (err) {
      throw new Error(err);
    }
  };
};

// Owner methods

/*
  POST Owners
  @formDatas: [{
    businessName: string,
    firstName: string,
    lastName: string,
    phoneType: string,
    phoneNumber: string,
    email: string,
    lineOne: string,
    lineTwo: string,
    city: string,
    state: string,
    zipcode: string,
    county: string,
    ownerNumber: int
  }]
*/
export const createOwners = () => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);
    const registrationId = state.getIn(['brandRegistration', 'registration', 'id']);
    const owners = state.getIn(['brandRegistration', 'owners']).toJS();
    const isValid = !owners.find((o) => !o.valid);

    if (isValid) {
      dispatch(setLoadingIndicator(true));

      try {
        const responses = await Promise.all(owners.map((o, index) => {
          const owner = { ...o };

          delete owner.sortId;
          delete owner.valid;

          if (owner.id) {
            return client.put(
              `/users/${userId}/registrations/${registrationId}/owners/${owner.id}`,
              owner,
            );
          }

          return client.post(
            `/users/${userId}/registrations/${registrationId}/owners`,
            owner,
          );
        }));

        const ownersResponse = responses.map(({ data }) => {
          return { ...data, sortId: data.id, childId: data.id  };
        });

        dispatch(setOwnersOrder(ownersResponse));

        dispatch(setLoadingIndicator(false));

        dispatch(push('/brands/new/address'));
      } catch (err) {
        dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
        dispatch(setLoadingIndicator(false));
      }
    }
  };
};

/*
  DELETE Owner
  @ownerId: string UUID
  @index: int
*/
export const deleteOwner = (ownerId, index) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);
    const registrationId = state.getIn(['brandRegistration', 'registration', 'id']);

    dispatch(setLoadingIndicator(true));

    try {
      await client.delete(`/users/${userId}/registrations/${registrationId}/owners/${ownerId}`);

      dispatch(removeOwner(index));

      dispatch(setLoadingIndicator(false));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
      dispatch(setLoadingIndicator(false));
    }
  };
};

/*
  PUT Address
  @formData: {
    type: string,
    lineOne: string,
    lineTwo: string,
    city: string,
    state: string,
    zipcode: string,
    county: string
  }
*/
export const updateBrandAddress = (formData) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);
    const registrationId = state.getIn(['brandRegistration', 'registration', 'id']);

    try {
      const { data } = await client.put(
        `/users/${userId}/registrations/${registrationId}/addresses`,
        formData,
      );

      const address = { ...data };

      delete address.id;

      dispatch(updateAddressSuccess(address));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
    }
  };
};

// Position methods
/*
  POST Position
  @position: string [ LS, RS, LR, RR, LH, RH, LJ, RJ, LN, RN, Li, D, D1, D2, D3, D4, Fa, CB, Wi, Ru, SE, Hd ]
*/
export const createPosition = (position) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);
    const registrationId = state.getIn(['brandRegistration', 'registration', 'id']);

    dispatch(setLoadingIndicator(true));

    try {
      await client.post(
        `/users/${userId}/registrations/${registrationId}/positions`,
        position,
      );

      dispatch(createPositionSuccess(position));

      dispatch(getCart());

      dispatch(setLoadingIndicator(false));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
      dispatch(setLoadingIndicator(false));
    }
  };
};

/*
  DELETE Position
  @position: string [ LS, RS, LR, RR, LH, RH, LJ, RJ, LN, RN, Li, D, D1, D2, D3, D4, Fa, CB, Wi, Ru, SE, Hd ]
*/
export const deletePosition = (position) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);
    const registrationId = state.getIn(['brandRegistration', 'registration', 'id']);

    dispatch(setLoadingIndicator(true));

    try {
      await client.delete(`/users/${userId}/registrations/${registrationId}/positions/${position}`);

      dispatch(deletePositionSuccess(position));

      dispatch(getCart());

      dispatch(setLoadingIndicator(false));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
      dispatch(setLoadingIndicator(false));
    }
  };
};

export const uploadBrandImage = (blob, regId) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'data', 'user', 'id']);
    const registrationId = state.getIn(['brandRegistration', 'registration', 'id']);

    const formData = new FormData();

    formData.append('file', blob);

    try {
      await client.post(
        `/users/${userId}/registrations/${regId || registrationId}/photos`,
        formData,
      );
    } catch (err) {
      throw new Error(err);
    }
  };
};

export default reducer;
