import { fromJS } from 'immutable';
import { handleActions, createAction } from 'redux-actions';

import { GENERAL_ERROR } from 'common/constants';

const GET_MY_BRANDS_SUCCESS = 'app/brand/GET_MY_BRANDS_SUCCESS';
const GET_BRANDS_SUCCESS = 'app/brand/GET_BRANDS_SUCCESS';
const GET_BRAND_SUCCESS = 'app/brand/GET_BRAND_SUCCESS';
const GET_BRAND_DETAILS_SUCCESS = 'app/brand/GET_BRAND_DETAILS_SUCCESS';
const ADD_NOTE_SUCCESS = 'app/brand/ADD_NOTE_SUCCESS';
const UPDATE_NOTE_SUCCESS = 'app/brand/UPDATE_NOTE_SUCCESS';
const UPDATE_BRAND_IN_LIST = 'app/brand/UPDATE_BRAND_IN_LIST';
const DELETE_NOTE_SUCCESS = 'app/brand/DELETE_NOTE_SUCCESS';
const REMOVE_BRAND_OWNER_PHONE = 'app/brand/REMOVE_BRAND_OWNER_PHONE';
const SET_BRAND_PENDING_INDICATOR = 'app/brand/SET_BRAND_PENDING_INDICATOR';
const RESET_BRANDS = 'app/brand/RESET_BRANDS';

const initialState = fromJS({
  ui: {
    pending: true,
    myBrandsPending: true,
    query: '',
  },
  data: {
    brands: {},
    myBrands: [],
    brand: {},
    pinLink: {},
    notes: [],
  },
});

function updateBrandOwner(brands, brandId, owner) {
  if (brands && brands.size > 0) {
    return brands.update(
      brands.findIndex((item) => item.get('id') === brandId),
      (item) => {
        return item.set(
          'brandOwners',
          item.get('brandOwners').update(
            item.get('brandOwners').findIndex((brandOwner) => brandOwner.get('id') === owner.id),
            () => {
              return fromJS(owner);
            },
          ),
        );
      },
    );
  }
  return brands;
}

function removeOwnerPhone(brands, brandId, ownerId, phoneId) {
  if (brands && brands.size > 0) {
    return brands.update(
      brands.findIndex((item) => item.get('id') === brandId),
      (item) => {
        return item.set(
          'brandOwners',
          item.get('brandOwners').update(
            item.get('brandOwners').findIndex((brandOwner) => brandOwner.get('id') === ownerId),
            (owner) => {
              const phones = owner.get('phones')
                .toJS()
                .filter((p) => p.id !== phoneId);

              return owner.set('phones', fromJS(phones));
            },
          ),
        );
      },
    );
  }
  return brands;
}

const reducer = handleActions({
  [GET_MY_BRANDS_SUCCESS]: (state, { payload: { brands } }) => {
    return state
      .setIn(['data', 'myBrands'], fromJS(brands))
      .setIn(['ui', 'myBrandsPending'], false);
  },
  [GET_BRANDS_SUCCESS]: (state, { payload: { brands } }) => {
    return state.setIn(['data', 'brands'], fromJS(brands));
  },
  [GET_BRAND_SUCCESS]: (state, { payload: { brand } }) => {
    return state
      .setIn(['data', 'brand'], fromJS(brand));
  },
  [GET_BRAND_DETAILS_SUCCESS]: (state, { payload: { brand, pin, pinLink, notes } }) => {
    return state
      .setIn(['data', 'brand'], fromJS({ ...brand, ...pin }))
      .setIn(['data', 'pinLink'], fromJS(pinLink))
      .setIn(['data', 'notes'], fromJS(notes))
      .setIn(['ui', 'pending'], false);
  },
  [ADD_NOTE_SUCCESS]: (state, { payload: { note } }) => {
    return state.updateIn(
      ['data', 'notes'],
      (notes) => {
        return notes.set(notes.size, fromJS(note));
      },
    );
  },
  [UPDATE_NOTE_SUCCESS]: (state, { payload: { note } }) => {
    return state.updateIn(
      ['data', 'notes'],
      (notes) => {
        return notes.update(
          notes.findIndex((item) => item.get('id') === note.id),
          () => {
            return fromJS(note);
          },
        );
      },
    );
  },
  [UPDATE_BRAND_IN_LIST]: (state, { payload: { brandId, owner } }) => {
    return state
      .updateIn(
        ['data', 'brands', 'content'],
        (brands) => updateBrandOwner(brands, brandId, owner),
      )
      .updateIn(
        ['data', 'myBrands'],
        (brands) => updateBrandOwner(brands, brandId, owner),
      );
  },
  [DELETE_NOTE_SUCCESS]: (state, { payload: { id } }) => {
    return state.updateIn(
      ['data', 'notes'],
      (notes) => {
        return notes.filter((a) => a.get('id') !== id);
      },
    );
  },
  [REMOVE_BRAND_OWNER_PHONE]: (state, { payload: { brandId, ownerId, phoneId } }) => {
    return state
      .updateIn(
        ['data', 'brands', 'content'],
        (brands) => removeOwnerPhone(brands, brandId, ownerId, phoneId),
      )
      .updateIn(
        ['data', 'myBrands'],
        (brands) => removeOwnerPhone(brands, brandId, ownerId, phoneId),
      );
  },
  [SET_BRAND_PENDING_INDICATOR]: (state, { payload: { pending } }) => {
    return state.setIn(['ui', 'pending'], pending);
  },
  [RESET_BRANDS]: () => {
    return fromJS(initialState);
  },
}, initialState);

export const getMyBrandsSuccess = createAction(GET_MY_BRANDS_SUCCESS, (brands) => ({ brands }));
export const getBrandsSuccess = createAction(GET_BRANDS_SUCCESS, (brands) => ({ brands }));
export const getBrandSuccess = createAction(GET_BRAND_SUCCESS, (brand) => ({ brand }));
export const getBrandDetailsSuccess = createAction(
  GET_BRAND_DETAILS_SUCCESS,
  (brand, pin, pinLink, notes) => {
    return { brand, pin, pinLink, notes };
  },
);
export const addNoteSuccess = createAction(ADD_NOTE_SUCCESS, (note) => ({ note }));
export const updateNoteSuccess = createAction(UPDATE_NOTE_SUCCESS, (note) => ({ note }));
export const updateBrandInList = createAction(
  UPDATE_BRAND_IN_LIST,
  (brandId, owner) => ({ brandId, owner }),
);
export const deleteNoteSuccess = createAction(DELETE_NOTE_SUCCESS, (id) => ({ id }));
export const removeBrandOwnerPhone = createAction(REMOVE_BRAND_OWNER_PHONE, (brandId, ownerId, phoneId) => ({ brandId, ownerId, phoneId }));
export const setBrandPendingIndicator = createAction(SET_BRAND_PENDING_INDICATOR, (pending) => ({ pending }));
export const resetBrands = createAction(RESET_BRANDS);

/*
 * @bool forceRefresh : force refresh brand list or get from cache
 */
export const getMyBrands = (forceRefresh = false) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(['user', 'data']).toJS();

    if (user) {
      try {
        const response = await createAuthClient().get(`/users/${user.id}/brands`);

        dispatch(getMyBrandsSuccess(response.data));
      } catch (err) {
        dispatch(getMyBrandsSuccess([]));
      }
    }
  };
};

/*
 * @string query : search query
 * @int offset : search result offset
 * @function errorCallback : on request error callback
 */
export const getBrands = (query = '', offset = 0, errorCallback) => {
  return async function (dispatch, getState, { createAuthClient }) {
    try {
      const response = await createAuthClient().get(
        '/brands/',
        {
          params: {
            query,
            page: offset,
            size: 10,
          },
        },
      );

      dispatch(getBrandsSuccess(response.data));
    } catch (err) {
      if (typeof errorCallback === 'function') {
        errorCallback(err);
      }
    }
  };
};

/*
 * @string query : search query
 * @int offset : search result offset
 * @function errorCallback : on request error callback
 */
export const getBrandsByLetters = (letters = '', offset = 0, successCallback, errorCallback) => {
  return async function (dispatch, getState, { createAuthClient }) {
    try {
      const response = await createAuthClient().get(
        '/brands/',
        {
          params: {
            letters,
            page: offset,
            size: 10,
          },
        },
      );
      dispatch(getBrandsSuccess(response.data));
      successCallback();
    } catch (err) {
      if (typeof errorCallback === 'function') {
        errorCallback(err);
      }
    }
  };
};

/*
 * @int id : brand.id
 */
export const getBrand = (id) => {
  return async (dispatch, getState, { createAuthClient }) => {
    try {
      const response = await createAuthClient().get(`/brands/${id}`);

      dispatch(getBrandSuccess(response.data));
      dispatch(setBrandPendingIndicator(false));
    } catch (err) {
      dispatch(setBrandPendingIndicator(false));
    }
  };
};

/*
 * @int id : brand.id
 */
export const getBrandDetails = (id) => {
  return async (dispatch, getState, { createAuthClient }) => {
    const client = createAuthClient();

    try {
      const responses = await Promise.all([
        client.get(`/brands/${id}`),
        client.get(`/admin/brands/${id}/pin`),
        client.get(`/admin/brands/${id}/pinLink`),
        client.get(`/admin/brands/${id}/notes`),
      ]);

      dispatch(getBrandDetailsSuccess(
        responses[0].data,
        responses[1].data,
        responses[2].data,
        responses[3].data,
      ));

      dispatch(setBrandPendingIndicator(false));
    } catch (err) {
      dispatch(setBrandPendingIndicator(false));
    }
  };
};

export const addNote = (note) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const brandId = state.getIn(['brand', 'data', 'brand', 'id']);

    try {
      const response = await createAuthClient()
        .post(`/admin/brands/${brandId}/notes`, note);

      dispatch(addNoteSuccess(response.data));
    } catch (err) {
      throw new Error(err.response.data ? err.response.data.message : GENERAL_ERROR);
    }
  };
};

export const updateNote = (note) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const brandId = state.getIn(['brand', 'data', 'brand', 'id']);

    try {
      const response = await createAuthClient()
        .put(`/admin/brands/${brandId}/notes`, note);

      dispatch(updateNoteSuccess(response.data));
    } catch (err) {
      throw new Error(err.response.data ? err.response.data.message : GENERAL_ERROR);
    }
  };
};

export const deleteNote = (id) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const brandId = state.getIn(['brand', 'data', 'brand', 'id']);

    try {
      await createAuthClient().delete(`/admin/brands/${brandId}/notes/${id}`);

      dispatch(deleteNoteSuccess(id));
    } catch (err) {
      throw new Error(err.response.data ? err.response.data.message : GENERAL_ERROR);
    }
  };
};

export const deletePhone = (brandId, ownerId, phoneId) => {
  return async function (dispatch, getState, { createAuthClient }) {
    try {
      const response = await createAuthClient()
        .delete(`/admin/brands/${brandId}/owners/${ownerId}/phones/${phoneId}`);

      dispatch(removeBrandOwnerPhone(brandId, ownerId, phoneId));

      return response.data;
    } catch (err) {
      throw new Error(err.response.data ? err.response.data.message : GENERAL_ERROR);
    }
  };
};

export default reducer;
