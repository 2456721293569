import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  makeStyles,
  Avatar,
  Button,
  Card,
} from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { _empty, hasAccount, isAdmin, updateOwner } from 'util/APIUtils';
import { updateBrandInList } from 'modules/brand';
import WalletCard from 'containers/walletCard/index';
import ContactInformation from 'components/contactInformation';
import PinLinking from './PinLinking';
import Error from "../../Error";

const messageBlockStyle = {
  display: 'block',
  maxWidth: 160,
};

const useStyles = makeStyles(() => ({
  ownerNumberContainer: {
    padding: 0,
    margin: 0,
  },
  ownerNumber: {
    width: 32,
    height: 32,
    margin: '-.3em .3em 0 -.8em',
    backgroundColor: '#fff',
    color: '#ccc',
    border: '1px solid #ddd',
  },
  ownersContainer: {
    textAlign: 'left',
    padding: '.3em .7em 0 1em',
    margin: '0'
  },
  ownerCard: {
    backgroundColor: 'transparent',
    overflow: 'visible',
    display: 'flex',
    margin: '0',
    padding: '.5em 8px .5em 0',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  linkPin: {
    display: 'flex',
    padding: '.4em 0 .2em',
  },
  buttonIcon: {
    marginLeft: 8,
    verticalAlign: 'middle',
  },
  address: {
    padding: '.6em .8em .5em 20px'
  },
  control: {
    padding: 0,
    margin: 'auto',
  },
  printCard: {
  },
  contactInfo: {
    paddingBottom: '.5em'
  },
  messageBlock: messageBlockStyle
}));

const ensureEmails = owner => {
  if (owner) {
    if (_empty(owner.emails)) {
      return Object.assign({}, owner, { emails: [{ email: '' }] });
    }
  }
  return owner;
};

export default function BrandOwner({
  hideButtons,
  expired,
  owner,
  user,
  displayClaimBrand,
  reloadDisplayedBrands,
  brandId,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const code = owner && owner.lookupToken;
  const classes = useStyles();
  const [localOwner, setLocalOwner] = useState(ensureEmails(owner));
  const { ownerNumber, businessName, firstName, lastName } = localOwner;
  const [brandJustClaimed, setBrandJustClaimed] = useState(false);

  const handleLinkingSuccess = (updatedOwner) => {
    setBrandJustClaimed(true);
    setLocalOwner(updatedOwner);
    reloadDisplayedBrands();
  };
  const { ownedByLoggedInUser } = owner;
  const admin = isAdmin(user);
  // todo fix on server side
  const adminOrOwner = (!expired && ownedByLoggedInUser) || admin;
  const displayLinkPin = displayClaimBrand && ((!ownedByLoggedInUser && hasAccount(user)) || admin);

  const displayPrintWallet = code && adminOrOwner;
  const walletRef = useRef();
  const saveUpdatedContact = () => {
    setError(null);
    updateOwner(localOwner)
      .then(savedOwner => {
        setLocalOwner(savedOwner);
        dispatch(updateBrandInList(brandId, savedOwner));
      })
      .catch(error =>
        setError((error && error.message) || 'Oops! Something went wrong. Please try again!')
      );
  };
  const updateLocalOwner = (updatedOwner) => {
    setLocalOwner(updatedOwner);
  };

  return (
    <div className={clsx(classes.ownersContainer, 'brand-owner')}>
      <Card className={classes.ownerCard} style={{ paddingBottom: 0 }}>
        <div className={classes.ownerNumberContainer}>
          <Avatar className={classes.ownerNumber}>
            <h4>{ownerNumber}</h4>
          </Avatar>
        </div>
        <div className={classes.contactInfo}>
          { error && <Error styles={messageBlockStyle} error={error}/>}
          {businessName && businessName.trim().length > 0 && <strong>{businessName}<br /></strong>}
          {!!(firstName || lastName) && <strong>{firstName} {lastName}<br /></strong>}
          <ContactInformation
            user={user}
            displayEditButton={!hideButtons && adminOrOwner}
            handleOwnerChange={updateLocalOwner}
            saveOwner={saveUpdatedContact}
            owner={localOwner}
            displayEmail={ownedByLoggedInUser || isAdmin(user)}
            brandId={brandId}
          />
          {!hideButtons && displayLinkPin && !brandJustClaimed && (
            <div className={classes.linkPin}>
              <PinLinking className={classes.control} ownerId={localOwner.id} successHandler={handleLinkingSuccess} />
            </div>
          )}
          {!hideButtons && displayPrintWallet && (
            <div>
              <ReactToPrint
                trigger={() => (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={clsx(classes.printCard, 'brand-owner-print-wallet')}
                  >
                    Wallet Card&nbsp;<PrintIcon />
                  </Button>
                )}
                content={() => walletRef.current}
              />
              <div style={{ display: "none" }}>
                <div ref={walletRef}>
                  <WalletCard owner={owner} code={code} enqueueSnackbar={enqueueSnackbar} />
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
