import React, { Suspense, lazy } from 'react';
import LoadingIndicator from 'components/LoadingIndicator';

function LazyComponent(Component) {
  return props => (
    <Suspense fallback={<LoadingIndicator />}>
      <Component {...props} />
    </Suspense>
  );
}

export default (component) => {
  return LazyComponent(lazy(() => component));
};
