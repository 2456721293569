import { createSelector } from 'reselect';

const selectBrandDomain = (state) => state.get('brand');

export const selectBrands = createSelector(
  selectBrandDomain,
  (state) => {
    const brandResults = state.getIn(['data', 'brands']).toJS();

    return brandResults.content || [];
  },
);

export const selectBrandResultsLength = createSelector(
  selectBrandDomain,
  (state) => {
    const brandResults = state.getIn(['data', 'brands']).toJS();

    return brandResults || { totalElements: 0, totalPages: 0 };
  },
);

export const selectMyBrands = createSelector(
  selectBrandDomain,
  (state) => state.getIn(['data', 'myBrands']).toJS(),
);

export const selectMyBrandsIsPending = createSelector(
  selectBrandDomain,
  (state) => state.getIn(['ui', 'myBrandsPending']),
);

export const selectBrandIsPending = createSelector(
  selectBrandDomain,
  (state) => state.getIn(['ui', 'pending']),
);

export const selectBrand = createSelector(
  selectBrandDomain,
  (state) => state.getIn(['data', 'brand']).toJS(),
);

export const selectBrandPinLink = createSelector(
  selectBrandDomain,
  (state) => {
    const pinLink = state.getIn(['data', 'pinLink']).toJS();
    return {
      ...pinLink,
      renewUrl: (pinLink.brandHash && pinLink.pinHash) ? `/${pinLink.brandHash}/${pinLink.pinHash}` : null,
    };
  },
);

export const selectBrandNotes = createSelector(
  selectBrandDomain,
  (state) => state.getIn(['data', 'notes']).toJS(),
);
