import React from "react";
import { NavLink } from "react-router-dom";

import { useSubMenuStyles } from "../styles";
import { useSelector } from "react-redux";
import { selectIsUserAdmin } from "../../../selectors/user";

const SubMenu = () => {
  const classes = useSubMenuStyles();
  const isAdmin = useSelector(selectIsUserAdmin);

  return (
    <ul className={classes.wrapper}>
      {isAdmin && (
        <li>
          <NavLink
            exact
            to="/brands/new"
            className={classes.link}
            activeClassName="active"
          >
            register
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          exact
          to="/"
          className={classes.link}
          activeClassName="active"
          data-id="tCeG8"
        >
          search
        </NavLink>
      </li>
      <li>
        <NavLink
          exact
          to="/my-brands"
          className={classes.link}
          activeClassName="active"
          data-id="aMXu9"
        >
          my brands
        </NavLink>
      </li>
      {/* <li>
        <NavLink exact to="/brands/orders" className={classes.link} activeClassName="active">order history</NavLink>
      </li> */}
      <li>
        <NavLink
          exact
          to="/help"
          className={classes.link}
          activeClassName="active"
          data-id="XHa8p"
        >
          help
        </NavLink>
      </li>
    </ul>
  );
};

export default SubMenu;
