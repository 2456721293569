import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '40px 20px 0',
  },
  divider: {
    padding: '0 .9em',
  }
}));

export default function Privacy() {
  const classes = useStyles();

  const buildDivider = () => {
    return (
      <span className={classes.divider}>
        &bull;
      </span>
    );
  };

  return (
    <div className={classes.root}>
      <Link to="/help" data-id="CEtvF">Contact</Link>
      {buildDivider()}
      <a href="https://ag.utah.gov/about/udaf-responsibilities-divisions-programs/" target="programs" data-id="XnjR6">
        Divisions &amp; Programs
      </a>
      {buildDivider()}
      <a href="https://www.utah.gov/privacypolicy.html" target="privacy" data-id="5P2KL">
        Privacy Policy
      </a>
      {buildDivider()}
      <a href="https://www.utah.gov/disclaimer.html" target="tos" data-id="7Y3fT">
        Terms of Use
      </a>
      {buildDivider()}
      <a href="https://www.utah.gov/accessibility.html" target="accessibility" data-id="cZl42">
        Accessibility
      </a>
      {/* todo when we incorporate other states we will need to customize the privacy, terms and accessibility. But for now, just point to Utah's docs. */}
      {/*<Link to="/privacy-policy">Privacy Policy</Link>*/}
      {/*{buildDivider()}*/}
      {/*<Link to="/terms-of-use">Terms of Use</Link>*/}
      {/*{buildDivider()}*/}
      {/*<Link to="/accessibility">Accessibility</Link>*/}
    </div>
  );
}
