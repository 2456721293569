/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";
import user from "modules/user";
import brand from "modules/brand";
import cart from "modules/cart";
import auth from "modules/auth";
import brandRegistration from "modules/brandRegistration";
/* BEGIN -- Admin Services */
import adminInspectors from "modules/admininspectors";
/* END -- Admin Services */

/* BEGIN -- inspector contact link */
import inspectorsContactLink from "modules/inspectorsContactLink";
/* END -- Admin Services */

/**
 * Creates the main reducer
 */
export default function createReducer(injectedReducers = {}, history) {
  return combineReducers({
    router: connectRouter(history),
    user,
    brand,
    cart,
    auth,
    brandRegistration,
    /*BEGIN -- Admin Services */
    adminInspectors,
    /* END -- Admin Services */
    /*BEGIN -- Inspectors Contact Link */
    inspectorsContactLink,
    /* END -- Inspectors Contact Link */
    ...injectedReducers,
  });
}
