import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  error: {
    color: '#f00',
    paddingBottom: '1em',
    '& a': {
      color: '#00a1f3',
      textDecoration: 'underline',
      whiteSpace: 'nowrap',
    }
  }
}));

export default function ({error, styles}) {
  const classes = useStyles();
  return <span className={classes.error} style={styles}>
    {error}<br/><br/>
    For help, please <Link to="/help/brandRecorder" target="recorder">contact the Brand Recorder</Link>
  </span>
}