import React from 'react';
import {
  makeStyles,
  Divider,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: '1em'
  },
  instructions: {
    padding: 0,
    textAlign: 'left'
  },
}));

const ClaimNotice = () => {
  const classes = useStyles();

  return (
    <div className={classes.instructions}>
      <Divider />
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Claim your Brand
      </Typography>
      Are you listed as a brand owner on this brand? Attach this brand to your logged in account by:
      <ol>
        <li>clicking the [My Brand] button</li>
        <li>then entering the brand PIN received in your renewal letter in the space provided</li>
        <li>then click the [Claim] button</li>
      </ol>
      Your linked brand will then be displayed under the [My Brands] tab.
    </div>
  );
};

export default React.memo(ClaimNotice);
