import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";
import { useSnackbar } from "notistack";

import udafLogo from "img/udaf-logo.svg";
import { logoutUser } from "modules/user";
import {
  selectUser,
  selectIsUserAdmin,
  selectIsUserInspector,
} from "selectors/user";
import { HIDE_HEADER_FOR, SHOW_NAV_FOR } from "common/constants";
import NavItems from "./components/NavItems";
import SubMenu from "./components/SubMenu";
import MobileSlider from "./components/MobileSlider";
import { useNavigationStyles } from "./styles";

const Navigation = ({ inMaintenanceMode, history }) => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const classes = useNavigationStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = useSelector(selectIsUserAdmin);
  const isInspector = useSelector(selectIsUserInspector);
  const user = useSelector(selectUser);

  const handleResize = (width) => {
    if (width <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleLogout = async () => {
    history.push("/");
    await dispatch(logoutUser());
    enqueueSnackbar("You're safely logged out!", { variant: "success" });
  };

  useEffect(() => {
    const pathname = history.location.pathname;
    const hideHeader =
      HIDE_HEADER_FOR.filter((key) => {
        return pathname.startsWith(key);
      }).length > 0;
    const hideNav =
      SHOW_NAV_FOR.filter((key) => {
        return pathname.startsWith(key);
      }).length > 0 || pathname === "/";

    setShowHeader(!hideHeader);
    setShowNav(hideNav);
  }, [history.location.pathname]);

  if (!showHeader) {
    return null;
  }

  // console.log("isMobile: ", isMobile);
  return (
    <>
      <ReactResizeDetector handleWidth onResize={handleResize} />
      <header className={classes.header}>
        <nav className={classes.nav}>
          <div>
            {!inMaintenanceMode && isMobile && (
              <MobileSlider
                handleLogout={handleLogout}
                user={user}
                isAdmin={isAdmin}
                isInspector={isInspector}
              />
            )}
            <Link to="/" className={classes.brand} data-id="afX3I">
              <img
                src={udafLogo}
                alt="Utah Department of Agriculture and Food"
              />
              <span>Utah Brands</span>
            </Link>
          </div>
          {!inMaintenanceMode && (
            <NavItems
              isMobile={isMobile}
              handleLogout={handleLogout}
              user={user}
              isAdmin={isAdmin}
              isInspector={isInspector}
            />
          )}
        </nav>
        {showNav && !isMobile ? (
          <SubMenu />
        ) : (
          <div className={classes.subMenuPlaceholder} />
        )}
      </header>
    </>
  );
};

export default withRouter(Navigation);
