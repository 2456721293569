import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  SearchByQuery,
  SearchByDoodle,
  SearchByImage,
} from 'components/searchTypes';

const searchType = {
  TEXT: 'text',
  DOODLE: 'doodle',
  IMAGE: 'image',
  VOICE: 'voice',
};

const Search = ({
  isSearching,
  query,
  handleSearchFieldUpdates,
  handleImageSearch,
}) => {
  const [type, setType] = useState(searchType.TEXT);
  const [doodleImage, setDoodleImage] = useState(null);


  const handleSearchByText = () => {
    setType(searchType.TEXT);
  };

  const handleQueryTextChange = (query) => {
    handleSearchFieldUpdates(query);
  };

  const clearDoodle = () => {
    setDoodleImage(null);
  };

  if (type === searchType.IMAGE) {
    return (
      <SearchByImage
        onSearch={handleImageSearch}
        onClose={handleSearchByText}
      />
    );
  } else if (type === searchType.DOODLE) {
    return (
      <SearchByDoodle
        onClose={handleSearchByText}
        onSearch={handleImageSearch}
        doodleImage={doodleImage}
        clearDoodle={clearDoodle}
      />
    );
  }

  return (
    <SearchByQuery
      isSearching={isSearching}
      query={query}
      onChange={handleQueryTextChange}
      searchByDoodle={() => setType(searchType.DOODLE)}
      searchByImage={() => setType(searchType.IMAGE)}
    />
  );
};

Search.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  setIsSearching: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  handleSearchFieldUpdates: PropTypes.func.isRequired,
  handleImageSearch: PropTypes.func.isRequired,
};

export default Search;
