let id = 0;

const KEY_REGISTRATION = 'udaf_registration';

export const createId = () => id++;

// @registrationId: String UUID
export const setRegistrationSession = (registrationId) => {
  window.sessionStorage.setItem(KEY_REGISTRATION, registrationId);
};

export const getRegistrationSession = () => {
  return window.sessionStorage.getItem(KEY_REGISTRATION);
};

export const removeCachedRegistration = () => {
  return window.sessionStorage.removeItem(KEY_REGISTRATION);
};

export const convertImgToBase64 = (url, callback, outputFormat = 'image/png') => {
	let canvas = document.createElement('CANVAS');
	const ctx = canvas.getContext('2d');
  const img = new Image();

	img.crossOrigin = 'Anonymous';
	img.onload = () => {
		canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img,0,0);

    const dataURL = canvas.toDataURL(outputFormat);

    callback.call(this, dataURL);
    canvas = null;
  };

	img.src = url;
};
