import React, { useState } from 'react';
import {
  makeStyles,
  TextField,
  Button
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import clsx from 'clsx';

import { linkBrand } from 'util/APIUtils';
import Error from "../../Error";

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 0 .5em 0'
  },
  textField: {
    '& input.MuiOutlinedInput-input': {
      padding: '.4em .5em .3em',
    },
    '& label.MuiInputLabel-formControl': {
      transform: 'translate(.4em, .5em) scale(1)',
    },
    '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    width: '70px',
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
}));

function PinLinking({ ownerId, value, enqueueSnackbar, successHandler }) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  // Declare a new state variable, which we'll call "count"
  const [pinNumber, setPinNumber] = useState(value || '');

  const handlePinChange = event => {
    setPinNumber(event.target.value);
  };

  const handleSubmit = event => {
    setError(null);
    linkBrand(ownerId, pinNumber).then(updatedOwner => {
      enqueueSnackbar('You have successfully linked this brand to your account', { variant: 'success' });
      successHandler(updatedOwner);
    }).catch(error =>
      setError((error && error.message) || 'Oops! Something went wrong. Please try again!')
    );
    event.preventDefault();
  };
  return (
    <form className={classes.container} autoComplete="off" onSubmit={handleSubmit}>
      { error && <Error styles={{ display: 'block', maxWidth: 160, }} error={error}/>}
      <TextField
        label="Pin"
        className={classes.textField}
        value={pinNumber}
        onChange={handlePinChange}
        variant="outlined"
        required
      />
      <Button
        variant="contained"
        margin="dense"
        size="small"
        color="primary"
        className={clsx(classes.button, 'brand-owner-claim')}
        type="submit"
      >
        Claim
      </Button>
    </form>
  );
}
export default withSnackbar(PinLinking);