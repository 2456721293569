import axios from "axios";
import { fromJS } from "immutable";
import { handleActions, createAction } from "redux-actions";
import { GENERAL_ERROR } from "common/constants";
/*
GET_INSPECTION_DATA_SUCCESS
UPDATE_INSPECTION_DATA_IN_LIST
SET_INSPECTION_PENDING_INDICATOR
UPDATE_PAYMENT_DATA_SUCCESS
SET_PAYMENT_PENDING_INDICATOR
*/

const GET_INSPECTORS_SUCCESS = "app/reconcile/GET_INSPECTORS_SUCCESS";
const SET_INSPECTORS_LOADING_INDICATOR =
  "app/reconcile/SET_INSPECTORS_LOADING_INDICATOR";
const SET_INSPECTORS_ERROR_MESSAGE =
  "app/reconcile/SET_INSPECTORS_ERROR_MESSAGE";

const initialState = fromJS({
  ui: {
    loading: false,
    error: "",
  },
  data: {
    items: [],
  },
});

// create actions
export const getInspectorsSuccess = createAction(
  GET_INSPECTORS_SUCCESS,
  (items) => ({ items })
);
export const setInspectorsLoadingIndicator = createAction(
  SET_INSPECTORS_LOADING_INDICATOR,
  (loading) => ({ loading })
);

export const setInspectorsErrorMessage = createAction(
  SET_INSPECTORS_ERROR_MESSAGE,
  (error) => ({ error })
);

// create reducer to handle actions
const reducer = handleActions(
  {
    [GET_INSPECTORS_SUCCESS]: (state, { payload: { items } }) => {
      return state
        .setIn(["data", "items"], fromJS(items))
        .setIn(["ui", "loading"], false)
        .setIn(["ui", "error"], fromJS(""));
    },
    [SET_INSPECTORS_LOADING_INDICATOR]: (state, { payload: { loading } }) => {
      return state.setIn(["ui", "loading"], loading);
    },
    [SET_INSPECTORS_ERROR_MESSAGE]: (state, { payload: { error } }) => {
      return state.setIn(["ui", "error"], fromJS(error ?? ""));
    },
  },
  initialState
);

/*
 *  retrieve list of inspectors
 */
const getAdminInspectorsListApiUrl = () => {
  let definedHost = (window.location.hostname ?? "").toLocaleLowerCase();

  let dataUrl;

  if (definedHost.includes("localhost")) {
    dataUrl = "https://localhost:7891/api/inspection/outbound/inspectorslist";
  } else if (definedHost.includes("staging")) {
    dataUrl =
      "https://agbrands-inspection-api-staging.brands.utah.gov/api/inspection/outbound/inspectorslist";
  } else {
    // TODO upate URL after prod deployment
    dataUrl =
      "https://agbrands-inspection-api.brands.utah.gov/api/inspection/outbound/inspectorslist";
  }
  return dataUrl;
};

export const getInspectors = () => {
  return async function (dispatch, getState) {
    const state = getState();

    try {
      const response = await axios.get(getAdminInspectorsListApiUrl());
      // console.log(response.data.items);
      dispatch(getInspectorsSuccess(response.data.items));
    } catch (err) {
      dispatch(getInspectorsSuccess([]));
    }
  };
};

export default reducer;
