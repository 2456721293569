import React from 'react';

export default function Phones({ phones, displayUnlisted }) {
  return (
    phones &&
    phones.map(
      ({ unlisted, type, number }, i) => {
        return (
          (!unlisted || displayUnlisted) &&
          number &&
          (
            <div key={i}>
              <a href={`tel:${number}`}>
                {type}: {number}
              </a>
              {unlisted && <em>unlisted</em>}
            </div>
          )
        );
      }
    )
  );
}