import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  makeStyles,
  Paper,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
  Create as CreateIcon,
  Remove as RemoveIcon,
  Crop169 as Crop169Icon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { UdafSketch } from '@acclaim/udaf-kit';

const { SketchField, Tools } = UdafSketch;

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: '500px',
    width: '100%',
    margin: '0 auto',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px 0',
    backgroundColor: '#FAFAFB',
    '& h5': {
      margin: '0',
      flex: '1',
      textAlign: 'left',
    },
    '& svg': {
      fontSize: '16px',
    },
  },
  tools: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: '#FAFAFB',
    borderBottom: '1px solid #dddddd',
  },
  tool: {
    background: 'none',
    border: 'none',
    borderRadius: '2px',
    height: '25px',
    width: '25px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    outline: 'none',
    cursor: 'pointer',
    flexWrap: 'wrap',
    marginRight: '2px',
    '& svg': {
      fontSize: '15px',
    },
    '&:hover': {
      backgroundColor: '#dddddd',
    },
  },
  body: {
    position: 'relative',
  },
  toolActive: {
    backgroundColor: '#dddddd',
  },
  search: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    backgroundColor: '#dddddd',
    height: '30px',
    width: '30px',
    '& svg': {
      fontSize: '18px',
      color: '#000000',
    },
  },
  previewWrapper: {
    maxWidth: '300px',
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    padding: '10px 10px 0',
    '& h4': {
      margin: '0 0 10px',
      padding: '0 10px',
    },
  },
  preview: {
    width: '100%',
  },
  previewClose: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    '& svg': {
      fontSize: '16px',
    },
  },
}));

const ToolBtn = (props) => {
  const classes = useStyles();
  const btnProps = { ...props };

  delete btnProps.active;

  return (
    <Tooltip title={props.tooltip}>
      <button
        className={clsx({
          [classes.tool]: true,
          [classes.toolActive]: props.active,
        })}
        {...btnProps}
      >
        {props.children}
      </button>
    </Tooltip>
  );
};

const SearchByDoodle = ({
  onClose,
  onSearch,
  doodleImage,
  clearDoodle,
}) => {
  const classes = useStyles();
  const sketchRef = useRef();
  const [tool, setTool] = useState(Tools.Pencil);

  const handleUndo = () => {
    if (sketchRef.current.canUndo()) {
      sketchRef.current.undo();
    }
  };

  const handleRedo = () => {
    if (sketchRef.current.canRedo()) {
      sketchRef.current.redo();
    }
  };

  const handleSearch = () => {
    if (sketchRef.current.canUndo()) {
      // const imageUrl = sketchRef.current._canvas.toDataURL('image/png');
      sketchRef.current._canvas.toBlob((blob) => {
        const file = new File(
          [blob],
          `${String(new Date().getTime())}_drawn`,
          {
            type:"image/png",
            lastModified: new Date(),
          },
        );
        onSearch(file);
      }, 'image/png');
    }
  };

  if (doodleImage) {
    return (
      <Paper className={classes.previewWrapper} elevation={2}>
        <h4>Search by Drawn Brand</h4>
        <img src={doodleImage} alt="Doodle search" className={classes.preview} />
        <IconButton size="small" className={classes.previewClose} onClick={clearDoodle}>
          <CloseIcon />
        </IconButton>
      </Paper>
    );
  }

  return (
    <Paper elevation={2} className={classes.wrapper}>
      <div className={classes.title}>
        <h5>Search Brands with a doodle</h5>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.tools}>
        <ToolBtn onClick={handleUndo} tooltip="Undo">
          <UndoIcon />
        </ToolBtn>
        <ToolBtn onClick={handleRedo} tooltip="Redo">
          <RedoIcon />
        </ToolBtn>
        <ToolBtn onClick={() => setTool(Tools.Pencil)} active={tool === Tools.Pencil} tooltip="Pencil">
          <CreateIcon />
        </ToolBtn>
        <ToolBtn onClick={() => setTool(Tools.Line)} active={tool === Tools.Line} tooltip="Line tool">
          <RemoveIcon />
        </ToolBtn>
        <ToolBtn onClick={() => setTool(Tools.Rectangle)} active={tool === Tools.Rectangle} tooltip="Rectangle tool">
          <Crop169Icon />
        </ToolBtn>
        <ToolBtn onClick={() => setTool(Tools.Circle)} active={tool === Tools.Circle} tooltip="Circle tool">
          <RadioButtonUncheckedIcon />
        </ToolBtn>
      </div>
      <div className={classes.body}>
        <SketchField
          name="searchSketch"
          height={200}
          tool={tool}
          lineColor="black"
          lineWidth={8}
          ref={sketchRef}
          imageFormat="png"
        />
        <IconButton className={classes.search} onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </div>
    </Paper>
  );
};

SearchByDoodle.propTypes = {
  doodleImage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  clearDoodle: PropTypes.func.isRequired,
};

export default SearchByDoodle;
