import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TablePagination } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    padding: '20px 0',
    '& > .MuiTablePagination-toolbar': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& > .MuiTablePagination-toolbar > p': {
      marginBottom: '15px',
      fontSize: '15px',
    },
  },
  pagination: {
    '& button': {
      borderColor: theme.custom.gray300,
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: theme.custom.primary,
      borderColor: theme.custom.primary,
      fontWeight: 'bold',
      color: '#ffffff',
    },
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
    '& .MuiPagination-ul li': {
      marginBottom: '5px',
    },
  },
}));

const SearchPagination = ({
  count,
  page,
  onChange,
  rowPerPage,
  total,
}) => {
  const classes = useStyles();

  return (
    <TablePagination
      className={clsx({
        [classes.tablePagination]: true,
        'brand-search-pagination': true,
      })}
      component="div"
      count={total}
      page={page - 1}
      onChangePage={onChange}
      rowsPerPage={rowPerPage}
      rowsPerPageOptions={[rowPerPage]}
      labelDisplayedRows={({ from, to, count }) => {
        const rowTo = (from + (rowPerPage - 1)) > total ? total : from + (rowPerPage - 1);

        return `Displaying ${from}-${rowTo} of ${count !== -1 ? `${total} results` : `more than ${to}`}`;
      }}
      ActionsComponent={() => {
        return (
          <Pagination
            className={clsx({
              [classes.pagination]: true,
              'brand-search-pagination-action': true,
            })}
            count={count}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={onChange}
            siblingCount={2}
          />
        );
      }}
    />
  );
};

SearchPagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowPerPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchPagination;
