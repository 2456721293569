import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { useAuthentication } from "@acclaim/udaf-kit";

import { getAdminUrl, getInspectionUrl, isAdminEdit } from "util/APIUtils";
import placeholder from "img/profile-placeholder.png";
import Cart from "./Cart";
import { useNavItemsStyles } from "../styles";

const NavItems = ({ isMobile, handleLogout, user, isAdmin, isInspector }) => {
  const classes = useNavItemsStyles();
  const { login, signup } = useAuthentication();

  if (isMobile) {
    return (
      <ul className={classes.wrapper}>
        {!user && (
          <li>
            <button className={classes.link} onClick={login}>
              login
            </button>
          </li>
        )}
        <li>
          <Link className={classes.link} to="/cart">
            <Cart />
          </Link>
        </li>
      </ul>
    );
  }

  if (user) {
    const adminUrl = getAdminUrl();
    const inspectionUrl = getInspectionUrl();

    return (
      <ul className={classes.wrapper}>
        <li>
          <span className={`${classes.link} ${classes.menu}`} data-id="5BYYP">
            <img
              className={classes.avatar}
              src={user.imageUrl || placeholder}
              alt={user.name}
            />
            <span>{user.name}</span>
            <ExpandMoreIcon />
          </span>
          <div className={classes.subMenu}>
            {isAdmin && (
              <a href={adminUrl} data-id="Ee4VV">
                Admin
              </a>
            )}
            {isInspector && (
              <a href={inspectionUrl} data-id="LqPih">
                Inspections App
              </a>
            )}
            {(isAdmin || isAdminEdit || isInspector) && (
              <Link to="/adminInspections" data-id="D4adm">
                Admin Inspections
              </Link>
            )}

            <Link to="/profile" data-id="ITAAI">
              View Profile
            </Link>
            <Link to="/profile/orders" data-id="nMsek">
              Order History
            </Link>
            <button onClick={handleLogout} data-id="MM4vR">
              Logout
            </button>
            <span style={{ padding: "5px 10px 10px", color: "#999" }}>
              Version {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </li>
        <li>
          <Link className={classes.link} to="/cart" data-id="n7hXZ">
            <Cart />
          </Link>
        </li>
      </ul>
    );
  }

  return (
    <ul className={classes.wrapper}>
      <li>
        <button className={classes.link} onClick={login} data-id="5MQzU">
          login
        </button>
      </li>
      <li>
        <button className={classes.link} onClick={signup} data-id="PXIMW">
          signup
        </button>
      </li>
    </ul>
  );
};

NavItems.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  user: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
  isInspector: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default NavItems;
