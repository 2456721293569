import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import '@acclaim/udaf-kit/dist/index.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { UdafContext, UdafAuthInterceptor } from '@acclaim/udaf-kit';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactGA from 'react-ga';

import configureStore from 'lib/configureStore';
import App from 'containers/app';
import { unregister } from 'registerServiceWorker';
import { SnackbarProvider } from 'notistack';
import theme from 'lib/theme';
import {
  IS_PROD_ENVIRONMENT,
  API_BASE_URL,
  AUTH_URL,
} from 'common/constants';

const history = createBrowserHistory();
const store = configureStore(history);

ReactGA.initialize(
  IS_PROD_ENVIRONMENT ?
    'UA-168847286-1'
    : process.env.REACT_APP_GA_CODE || 'UA-168776249-1');

ReactGA.pageview(window.location.pathname);

// Listen for history changes
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const OPTIONS = {
  API_BASE_URL,
  AUTH_URL,
};

unregister();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <UdafContext.Provider value={OPTIONS}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={3000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
          >
            <Switch>
              <Route
                exact
                path="/authenticate"
                component={UdafAuthInterceptor}
              />
              <Route path="/">
                <App />
              </Route>
            </Switch>
          </SnackbarProvider>
        </UdafContext.Provider>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
