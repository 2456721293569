import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import { useRouteMatch } from 'react-router-dom';

import StateSeal from 'img/seal-of-utah.svg';
import UdafLogo from 'img/udaf-logo.png';
import { getCertificate } from 'util/APIUtils';
import LoadingIndicator from 'components/LoadingIndicator';
import Address from 'components/Address';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FAFAFB',
    maxWidth: '1100px',
    margin: '0 auto',
    padding: 2,
  },
  certificate: {
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: 0,
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  logoContainer: {
    padding: 0,
    paddingTop: '10px',
    margin: 'auto',
  },
  logo: {
    width: 120,
  },
  department: {
    width: 311,
    textAlign: 'center',
    margin: 'auto',
  },
  sealContainer: {
    padding: '32px 0 8px 10px',
    margin: 'auto',
  },
  qrContainer: {
    margin: 'auto',
  },
  stateSeal: {
    width: 120,
  },
  qrCode: {
    width: 260,
  },
  certificateTitle: {
    fontFamily: ['Georgia', 'serif'],
    fontStyle: 'italic',
    fontSize: '1.3em',
    textAlign: 'center',
    margin: 0,
  },
  ownership: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    margin: 0,
    padding: '0 0 0 10px'
  },
  ownersName: {
    whiteSpace: 'nowrap',
    fontSize: '1.1em',
    margin: 0,
  },
  owner: {
    width: 188,
    margin: 'auto',
    paddingTop: '15px',
    textAlign: 'left',
  },
  address: {
    textTransform: 'capitalize',
    fontSize: '1em',
  },
  expiredDate: {
    color: '#f00',
    maxWidth: 340,
    textAlign: 'center',
    margin: 'auto',
  },
  statedOwnership: {
    maxWidth: 340,
    textAlign: 'center',
    margin: 'auto',
  },
  brand: {
    width: 188,
    textAlign: 'center',
    margin: 'auto',
    padding: '20px',
  },
  brandType: {
    fontSize: '1.3em',
    margin: 0,
  },
  position: {
    fontSize: '1.3em',
    margin: 0,
  },
  expiresOn: {
    fontFamily: ['Garamond', 'Georgia', 'serif'],
    margin: '0 0 5px',
  },
  border: {
    padding: 0,
  },
  innerBorder: {
    border: 'solid 11px #777',
    padding: '5px',
  },
  thinBorder: {
    border: 'solid 2px #999',
    padding: '2px',
  },
  thinBorderTwo: {
    border: 'solid 1px #000',
    padding: '10px',
    backgroundColor: '#B9D6C0',
  },
  thinBorderThree: {
    border: 'solid 1px #000',
    padding: '1px',
    backgroundColor: '#fff',
  },
  thinBorderFour: {
    border: 'solid 1px #999',
    padding: '6px',
    backgroundColor: '#fff',
  },
  innerInnerBorder: {
    border: 'dotted 3px #ccc',
  },
  innerInnerInnerBorder: {
    border: 'dotted 3px #ccc',
  },
  innerInnerSmallBorder: {
    border: 'dotted 2px #ccc',
  },
  innerInnerSmallInnerBorder: {
    border: 'dotted 2px #ccc',
    padding: '5px',
  },
  innerMostThinBorder: {
    border: 'solid 1px #aaa',
    padding: '1px',
    backgroundColor: '#fff',
  },
  innerMostThinBorderTwo: {
    border: 'solid 1px #ccc',
    padding: '0 20px 25px',
    backgroundColor: '#fff',
  },
  expired: {
    border: 'solid 1px #ccc',
    padding: '0 20px 25px',
  },
  brandImage: {
    width: 150,
    height: 150,
  }
}));

function Certificate({ certificate: passedCertificate }) {
  const match = useRouteMatch();
  const lookupCode = match && match.params && match.params.lookupCode;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [certificate, setCertificate] = useState(passedCertificate);

  useEffect(() => {
    if (!passedCertificate && lookupCode) {
      getCertificate(lookupCode)
        .then(foundCertificate => {
          setCertificate(foundCertificate);
        }).catch(error => {
          enqueueSnackbar((error && error.message) || 'Oops! Something went wrong. Please try again!', { variant: 'error' });
        });
    } else {
      setCertificate(passedCertificate);
    }
  }, [lookupCode, passedCertificate, enqueueSnackbar]);

  if (!certificate) {
    return <LoadingIndicator />;
  }
  const expired = certificate.status === 'EXPIRED';

  return (
    <div className={classes.root}>
      <div className={classes.certificate}>
        <div className={classes.border}>
          <div className={classes.innerBorder}>
            <div className={classes.thinBorder}>
              <div className={classes.thinBorderTwo}>
                <div className={classes.thinBorderThree}>
                  <div className={classes.thinBorderFour}>
                    <div className={classes.innerInnerBorder}>
                      <div className={classes.innerInnerInnerBorder}>
                        <div className={classes.innerInnerSmallBorder}>
                          <div className={classes.innerInnerSmallInnerBorder}>
                            <div className={classes.innerMostThinBorder}>
                              <div className={expired ? classes.expired : classes.innerMostThinBorderTwo}>
                                <div className={classes.header}>
                                  <div className={classes.logoContainer}>
                                    <img src={UdafLogo} alt="Utah Department of Agriculture and Food" className={classes.logo} />
                                  </div>
                                  <div className={classes.department}>
                                    <h1>Utah Department of Agriculture and Food</h1>
                                    <h1 className={classes.certificateTitle}>Certified Brand Owner</h1>
                                  </div>
                                  <div className={classes.sealContainer}>
                                    <img src={StateSeal} alt="State of Utah Seal" className={classes.stateSeal} />
                                  </div>
                                </div>

                                <div className={classes.ownership}>
                                  {certificate.brandOwners.map(({ firstName, lastName, phones, address, businessName }, index) => {
                                    return (
                                      <div key={index} className={classes.owner}>
                                        {businessName && <h2 className={classes.ownersName}>{businessName}</h2>}
                                        <h2 className={classes.ownersName}>{firstName} {lastName}</h2>
                                        <div className={classes.address}>
                                          <Address address={address} displayCounty={false} />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className={classes.ownership}>
                                  <div className={classes.brand}>
                                    <h4 className={classes.brandType}>{certificate.type}</h4>
                                    {certificate.brandImage && <img src={certificate.brandImage.imageUrl} className={classes.brandImage} alt={certificate.brandImage.description} />}
                                    <Typography>
                                      {certificate.brandNumber} &nbsp;
                                      {certificate.position}
                                    </Typography>
                                    <h5 className={classes.position}>{certificate.positionDescription}</h5>
                                  </div>
                                  <div className={classes.statedOwnership}>
                                    The owner(s) listed are duly certified for the Brand or Earmark
                                    shown in the position or district indicated.<br/><br/>
                                    {expired ? <div className={classes.expiredDate}>
                                      This certificate expired on
                                      <h3 className={classes.expiresOn}>{certificate.expirationDate}</h3>
                                    </div>
                                    : <div>
                                      This certification is good until transferred or until
                                      <h3 className={classes.expiresOn}>{certificate.expirationDate}</h3>
                                    </div>}
                                    Registered since
                                    <h3 className={classes.expiresOn}>{certificate.firstYear}</h3>
                                    Last registered Date
                                    <h3 className={classes.expiresOn}>{certificate.dateRegistered}</h3>
                                  </div>
                                  <div className={classes.qrContainer}>
                                    <img src={certificate.certificateCode && certificate.certificateCode.qrImage} alt="Validate Certificate with this QR Code" className={classes.qrCode} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certificate;
