import React, {useEffect, useRef, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  makeStyles,
  Card,
  Button,
} from '@material-ui/core';
import {
  ShoppingCart as ShoppingCartIcon,
  Print as PrintIcon,
} from '@material-ui/icons';
import ReactToPrint from 'react-to-print';
import clsx from 'clsx';

import Certificate from 'containers/certificate/index';
import {
  getCertificateUrl,
} from 'util/APIUtils';

import BrandOwner from './components/BrandOwner';
import {useSelector} from "react-redux";
import {selectIsUserInspector} from "selectors/user";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: 0,
  },
  card: {
    marginBottom: '15px',
    padding: '15px 30px',
  },
  cardPending: {
    backgroundColor: theme.custom.gray100,
  },
  cardContent: {
    padding: '.5em 1em'
  },
  brandOwners: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  brandImageContainer: {
    fontSize: '.8em',
    textAlign: 'center',
    width: '120px',
  },
  brandType: {
    display: 'block',
    textTransform: 'uppercase'
  },
  brandPosition: {
    display: 'block',
    textTransform: 'uppercase'
  },
  expiredText: {
    fontSize: '1.5em',
    fontWeight: 'bolder',
    color: '#f00',
    overflow: 'hidden',
  },
  qr: {
    width: 150,
  },
  adminControl: {
    width: 150,
  },
  brandDetailsContainer: {
    textAlign: 'left',
    padding: '.8em 1.5em',
  },
  buttonIcon: {
    marginLeft: 8,
    verticalAlign: 'middle',
  },
  loginLink: {
    padding: '1em',
    margin: 'auto',
  },
  printCert: {
    margin: '8px 0',
  },
  brandImage: {
    margin: '0',
    width: 64,
    height: 64,
    border: '1px solid #ccc'
  },
  brandButtons: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 10px',
  },
  pending: {
    fontSize: '13px',
    marginBottom: '10px',
    display: 'block',
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.custom.danger,
    fontStyle: 'italic',
  },
}));

function BrandListItem({
  hideButtons,
  isSearch,
  user,
  brand,
  cart,
  addToCart,
  reloadDisplayedBrands,
  displayClaimBrand,
  children,
}) {
  const history = useHistory();
  const classes = useStyles();
  const certRef = useRef();
  const { ownedByLoggedInUser } = brand;
  const { status, id: brandId, brandImage, brandOwners } = brand;
  const imageUrl = brandImage && brandImage.imageUrl;
  const imageDescription = brandImage && brandImage.description;
  const isInspectorOrAbove = useSelector(selectIsUserInspector);
  const renewable = status === 'RENEWABLE';
  const expired = status === 'EXPIRED';
  const showExpired = expired && (isInspectorOrAbove || ownedByLoggedInUser);
  const myBrandComponentId = `brand_${brandId}`;
  const { type, certificateCode, positionDescription } = brand;
  const code = certificateCode && certificateCode.key;
  const [transitionToCart, setTransitionToCart] = useState(false);
  const certificateUrl = code && getCertificateUrl(code);
  const isPendingRegistration = brand.typeOfRegistration === 'N' && !!brand.newBrandId;

  const reloadDisplayedBrandsWithBrandId = () => {
    reloadDisplayedBrands(brandId);
  };

  const handleRenewClick = event => {
    event.preventDefault();
    addToCart(brand.id)
    setTransitionToCart(true);
  };

  const handleRegistration = () => {
    history.push(`/brands/registration/${brand.newBrandId}`);
  }

  useEffect(()=> {
    if(cart && transitionToCart && cart.includes(brandId)) {
      setTransitionToCart(false);
      history.push('/cart');
    }
  }, [cart, transitionToCart, history, brandId])

  return (
    <Card
      id={myBrandComponentId}
      className={clsx({
        'ownedByLoggedInUser': ownedByLoggedInUser,
        'renewable': (!ownedByLoggedInUser && renewable),
        'expired': (showExpired && !renewable),
        'card-content': (!expired && !renewable && !ownedByLoggedInUser),
        'brand-item': true,
        [classes.card]: true,
        [classes.cardPending]: isPendingRegistration,
      })}
    >
      {isPendingRegistration && (
        <span className={classes.pending}>
          PENDING BRAND RECORDER APPROVAL
        </span>
      )}
      <div className={classes.root}>
        <div className={classes.brandImageContainer}>
          <span className={classes.brandType}>{type}</span>
          <img src={imageUrl} alt={(imageDescription || brand.description)} className={classes.brandImage} />
          <span className={classes.brandPosition}>{positionDescription}</span>
          { showExpired && <span className={classes.expiredText}>EXPIRED</span>}
        </div>
        {!hideButtons && <div className={classes.brandButtons}>
          {renewable && ownedByLoggedInUser &&
              <Button
                size="small"
                onClick={handleRenewClick}
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<ShoppingCartIcon />}
              >
                Renew
              </Button>
            }
          {children}
          <ReactToPrint
            trigger={() => (
              <Button
                size="small"
                className={clsx({
                  [classes.printCert]: true,
                  'brand-print-certificate': true,
                })}
                variant="outlined"
                color="primary"
              >
                Certificate&nbsp;<PrintIcon />
              </Button>
            )}
            content={() => certRef.current}
          />
          <div style={{ display: "none" }}>
            <div ref={certRef} className="brand-certificate">
              <Certificate certificate={brand} />
            </div>
          </div>
          {isPendingRegistration && (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleRegistration}
            >
              Register
            </Button>
          )}
        </div>}
        {!hideButtons && (
          <div>
            {!isSearch && certificateUrl && (
              <Link to={certificateUrl} className="brand-view-certificate">
                <img
                  src={brand.certificateCode.qrImage}
                  className={classes.qr}
                  alt="QR Code for validating brand certificate"
                />
              </Link>
            )}
          </div>
        )}
        {brandOwners && brandOwners.map(owner => (
          <BrandOwner
            className={classes.owner}
            hideButtons={hideButtons}
            displayClaimBrand={displayClaimBrand}
            reloadDisplayedBrands={reloadDisplayedBrandsWithBrandId}
            user={user}
            key={owner.id}
            owner={owner}
            brandId={brand.id}
            expired={expired}
          />
        ))}
      </div>
    </Card>
  );
}

export default BrandListItem;
