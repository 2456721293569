import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import {
  makeStyles,
  Paper,
  InputBase,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Image as ImageIcon,
  Gesture as GestureIcon,
  Mic as MicIcon,
  Stop as StopIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid grey',
    maxWidth: '600px',
    width: '100%',
    height: '50px',
    margin: '0 auto',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: '1',
    '& input::placeholder': {
      fontSize: '14px',
    },
  },
  searchLoader: {
    width: '22px !important',
    height: '22px !important',
  },
  iconButton: {
    padding: '5px',
    '& svg': {
      fontSize: '20px',
      opacity: '0.6',
    },
  },
  iconButtonRecord: {
    padding: '5px',
    '& svg': {
      fontSize: '20px',
      opacity: '0.6',
      color: 'red',
    },
  },
}));

let timeout;

const SearchByQuery = ({
  isSearching,
  query,
  onChange,
  searchByDoodle,
  searchByImage,
}) => {
  const classes = useStyles();
  // const isBetaTester = useSelector(selectIsUserBetaTester);
  const { startListening, stopListening, browserSupportsSpeechRecognition } = SpeechRecognition;
  const { transcript, resetTranscript, listening } = useSpeechRecognition({
    autoStart: false,
  });

  const clearRecorder = () => {
    resetTranscript();
    stopListening();
  };

  const resetSearch = () => {
    onChange('');
  };

  const renderVoiceSearch = () => {
    if (browserSupportsSpeechRecognition()) {
      if (listening) {
        return (
          <Tooltip title="Stop Recording">
            <IconButton
              className={classes.iconButtonRecord}
              size="small"
              onClick={clearRecorder}
            >
              <StopIcon />
            </IconButton>
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Search by Voice">
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={startListening}
          >
            <MicIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    if (transcript) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      timeout = setTimeout(() => {
        resetTranscript();
        stopListening();
        onChange(transcript);
      }, 800);
    }
  }, [transcript, onChange, stopListening, resetTranscript]);

  return (
    <Paper className={classes.wrapper}>
      <InputBase
        className={classes.input}
        value={query}
        onChange={(ev) => onChange(ev.target.value)}
        placeholder={listening ? 'Listening for voice input...' : 'Search by name, county, zipcode, brand position or brand number'}
        inputProps={{ 'aria-label': 'search google maps' }}
        required
      />
      {isSearching ?
        (
          <IconButton type="button" className={classes.iconButton} aria-label="search" size="small">
            <CircularProgress className={classes.searchLoader} />
          </IconButton>
        ) : (
          <>
            {!query && (
              <>
                {!listening && (
                  <>
                    <Tooltip title="Search with Drawing">
                      <IconButton
                        className={classes.iconButton}
                        size="small"
                        onClick={searchByDoodle}
                      >
                        <GestureIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Search by Image">
                      <IconButton
                        className={classes.iconButton}
                        size="small"
                        onClick={searchByImage}
                      >
                        <ImageIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {renderVoiceSearch()}
              </>
            )}
            {query && !listening && (
              <Tooltip title="Clear search">
                <IconButton type="button" className={classes.iconButton} size="small" onClick={resetSearch}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
            {!listening && (
              <>
                <IconButton type="button" className={classes.iconButton} aria-label="search" size="small">
                  {isSearching ? <CircularProgress className={classes.searchLoader} /> : <SearchIcon />}
                </IconButton>
              </>
            )}
          </>
        )
      }
    </Paper>
  );
};

SearchByQuery.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  searchByDoodle: PropTypes.func.isRequired,
  searchByImage: PropTypes.func.isRequired,
};

export default SearchByQuery;
