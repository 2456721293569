import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Drawer, IconButton } from "@material-ui/core";
import {
  Menu as MenuIcon,
  SearchOutlined as SearchOutlinedIcon,
  ImageOutlined as ImageOutlinedIcon,
  AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon,
  SupervisorAccountOutlined as SupervisorAccountOutlinedIcon,
  MeetingRoomOutlined as MeetingRoomOutlinedIcon,
  CreateOutlined as CreateOutlinedIcon,
  Person as PersonIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
} from "@material-ui/icons";
import { useAuthentication } from "@acclaim/udaf-kit";

import placeholder from "img/profile-placeholder.png";
import { getAdminUrl, getInspectionUrl, isAdminEdit } from "util/APIUtils";
import { useMobileSliderStyles } from "../styles";

const MobileSlider = ({ handleLogout, user, isAdmin, isInspector }) => {
  const classes = useMobileSliderStyles();
  const [open, setOpen] = useState(false);
  const { login, signup } = useAuthentication();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const adminUrl = getAdminUrl();
  const inspectionUrl = getInspectionUrl();

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        edge="start"
        className={classes.menuIcon}
        data-id="fQQ2g"
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        <div className={classes.wrapper} onClick={toggleDrawer}>
          {user && (
            <Link to="/profile" className={classes.user} data-id="RcJ8U">
              <img
                className={classes.avatar}
                src={user.imageUrl || placeholder}
                alt={user.name}
              />
              <span>{user.name}</span>
            </Link>
          )}
          <ul className={classes.menu}>
            {isAdmin && (
              <li>
                <a href={adminUrl} data-id="ZTACK">
                  <SupervisorAccountOutlinedIcon /> Admin
                </a>
              </li>
            )}
            {isInspector && (
              <li>
                <a href={inspectionUrl} data-id="WqCEk">
                  <AssignmentTurnedInIcon /> Inspections App
                </a>
              </li>
            )}

            {(isAdmin || isAdminEdit || isInspector) && (
              <li>
                <Link to="/adminInspections">
                  <SupervisorAccountOutlinedIcon /> Admin Inspections
                </Link>
              </li>
            )}

            {isAdmin && (
              <li>
                <Link to="/brands/new">
                  <AddPhotoAlternateOutlinedIcon /> Register
                </Link>
              </li>
            )}
            <li>
              <Link to="/" data-id="u7PMb">
                <SearchOutlinedIcon /> Search
              </Link>
            </li>
            {user && (
              <li>
                <Link to="/my-brands" data-id="ZPgs3">
                  <ImageOutlinedIcon /> My Brands
                </Link>
              </li>
            )}
            {user && (
              <li>
                <Link to="/profile" data-id="jy0Mc">
                  <PersonIcon /> View Profile
                </Link>
              </li>
            )}
            {user && (
              <li>
                <Link to="/profile/orders" data-id="QY17e">
                  <DescriptionOutlinedIcon /> Order History
                </Link>
              </li>
            )}
            <li>
              <Link to="/help" data-id="PpWVq">
                <HelpOutlineOutlinedIcon /> Help
              </Link>
            </li>
            {user ? (
              <li>
                <button onClick={handleLogout} data-id="BioMy">
                  <ExitToAppOutlinedIcon /> Logout
                </button>
              </li>
            ) : (
              <>
                <li>
                  <button onClick={login} data-id="lE57R">
                    <MeetingRoomOutlinedIcon /> Login
                  </button>
                </li>
                <li>
                  <button onClick={signup} data-id="hNEpp">
                    <CreateOutlinedIcon /> Signup
                  </button>
                </li>
              </>
            )}
          </ul>
          <span style={{ color: "#999", fontSize: "16px", paddingTop: "15px" }}>
            Version {process.env.REACT_APP_VERSION}
          </span>
        </div>
      </Drawer>
    </>
  );
};

MobileSlider.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  user: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
  isInspector: PropTypes.bool.isRequired,
};

export default MobileSlider;
