import { fromJS } from "immutable";
import { handleActions, createAction } from "redux-actions";
import { tokenUtil } from "@acclaim/udaf-kit";
// adding for admin edit
import { isAdmin, logout, isAdminEdit } from "util/APIUtils";
import { resetBrands } from "modules/brand";
import { resetOrder } from "modules/cart";
import { GENERAL_ERROR } from "common/constants";

const GET_USER_SUCCESS = "app/USER/GET_USER_SUCCESS";
const GET_PHONES_SUCCESS = "app/USER/GET_PHONES_SUCCESS";
const GET_ADDRESSES_SUCCESS = "app/USER/GET_ADDRESSES_SUCCESS";
const GET_ORDERS_SUCCESS = "app/USER/GET_ORDERS_SUCCESS";
const ADD_ADDRESS_SUCCESS = "app/USER/ADD_ADDRESS_SUCCESS";
const ADD_PHONE_SUCCESS = "app/USER/ADD_PHONE_SUCCESS";
const UPDATE_ADDRESS_SUCCESS = "app/USER/EDIT_ADDRESS_SUCCESS";
const UPDATE_PHONE_SUCCESS = "app/USER/UPDATE_PHONE_SUCCESS";
const DELETE_ADDRESS_SUCCESS = "app/USER/DELETE_ADDRESS_SUCCESS";
const DELETE_PHONE_SUCCESS = "app/USER/DELETE_PHONE_SUCCESS";
const RESET_USER = "app/USER/RESET_USER";
const SET_LOADING_INDICATOR = "app/USER/SET_LOADING_INDICATOR";
const SET_PHONE_LOADING_INDICATOR = "app/USER/SET_PHONE_LOADING_INDICATOR";
const SET_ADDRESS_LOADING_INDICATOR = "app/USER/SET_ADDRESS_LOADING_INDICATOR";
const SET_SELECTED_ORDER = "app/USER/SET_SELECTED_ORDER";

const initialState = fromJS({
  ui: {
    pending: true,
    phonesPending: true,
    addressesPending: true,
    isAdmin: false,
    isInspector: false,
    isBetaTester: false,
    selectedOrder: null,
    isAdminEdit: false,
  },
  data: {
    user: null,
    phones: [],
    addresses: [],
    orders: [],
  },
});

const reducer = handleActions(
  {
    [GET_USER_SUCCESS]: (state, { payload: { user } }) => {
      const isInspector = !!user.roles.find(({ role }) => {
        return ["Brand Inspector", "Brand Recorder", "Admin"].includes(role);
      });
      const isBetaTester =
        isInspector ||
        !!user.roles.find(({ role }) => {
          return ["Influencer"].includes(role);
        });
      // adding for admin edit
      return state
        .setIn(["data", "user"], fromJS(user))
        .setIn(["ui", "isAdmin"], isAdmin(user))
        .setIn(["ui", "isInspector"], isInspector)
        .setIn(["ui", "isBetaTester"], isBetaTester)
        .setIn(["ui", "isAdminEdit"], isAdminEdit(user));
    },
    [GET_PHONES_SUCCESS]: (state, { payload: { phones } }) => {
      return state.setIn(["data", "phones"], fromJS(phones));
    },
    [GET_ADDRESSES_SUCCESS]: (state, { payload: { addresses } }) => {
      return state.setIn(["data", "addresses"], fromJS(addresses));
    },
    [GET_ORDERS_SUCCESS]: (state, { payload: { orders } }) => {
      return state.setIn(["data", "orders"], fromJS(orders));
    },
    [ADD_ADDRESS_SUCCESS]: (state, { payload: { address } }) => {
      return state.updateIn(["data", "addresses"], (addresses) => {
        return addresses.set(addresses.size, fromJS(address));
      });
    },
    [ADD_PHONE_SUCCESS]: (state, { payload: { phone } }) => {
      return state.updateIn(["data", "phones"], (phones) => {
        return phones.set(phones.size, fromJS(phone));
      });
    },
    [UPDATE_ADDRESS_SUCCESS]: (state, { payload: { address } }) => {
      return state.updateIn(["data", "addresses"], (addresses) => {
        return addresses.update(
          addresses.findIndex((item) => item.get("id") === address.id),
          () => {
            return fromJS(address);
          }
        );
      });
    },
    [UPDATE_PHONE_SUCCESS]: (state, { payload: { phone } }) => {
      return state.updateIn(["data", "phones"], (phones) => {
        return phones.update(
          phones.findIndex((item) => item.get("id") === phone.id),
          () => {
            return fromJS(phone);
          }
        );
      });
    },
    [DELETE_ADDRESS_SUCCESS]: (state, { payload: { id } }) => {
      return state.updateIn(["data", "addresses"], (addresses) => {
        return addresses.filter((a) => a.get("id") !== id);
      });
    },
    [DELETE_PHONE_SUCCESS]: (state, { payload: { id } }) => {
      return state.updateIn(["data", "phones"], (phones) => {
        return phones.filter((a) => a.get("id") !== id);
      });
    },
    [RESET_USER]: (state) => {
      logout();
      return state
        .setIn(["data", "user"], null)
        .setIn(["ui", "isAdmin"], false)
        .setIn(["ui", "isAdminEdit"], false);
    },
    [SET_LOADING_INDICATOR]: (state, { payload: { loading } }) => {
      return state.setIn(["ui", "pending"], loading);
    },
    [SET_PHONE_LOADING_INDICATOR]: (state, { payload: { loading } }) => {
      return state.setIn(["ui", "phonesPending"], loading);
    },
    [SET_ADDRESS_LOADING_INDICATOR]: (state, { payload: { loading } }) => {
      return state.setIn(["ui", "addressesPending"], loading);
    },
    [SET_SELECTED_ORDER]: (state, { payload: { id } }) => {
      return state.setIn(["ui", "selectedOrder"], id);
    },
  },
  initialState
);

export const getUserSuccess = createAction(GET_USER_SUCCESS, (user) => ({
  user,
}));
export const getPhonesSuccess = createAction(GET_PHONES_SUCCESS, (phones) => ({
  phones,
}));
export const getAddressesSuccess = createAction(
  GET_ADDRESSES_SUCCESS,
  (addresses) => ({ addresses })
);
export const getOrdersSuccess = createAction(GET_ORDERS_SUCCESS, (orders) => ({
  orders,
}));
export const addAddressSuccess = createAction(
  ADD_ADDRESS_SUCCESS,
  (address) => ({ address })
);
export const addPhoneSuccess = createAction(ADD_PHONE_SUCCESS, (phone) => ({
  phone,
}));
export const updateAddressSuccess = createAction(
  UPDATE_ADDRESS_SUCCESS,
  (address) => ({ address })
);
export const updatePhoneSuccess = createAction(
  UPDATE_PHONE_SUCCESS,
  (phone) => ({ phone })
);
export const deleteAddressSuccess = createAction(
  DELETE_ADDRESS_SUCCESS,
  (id) => ({ id })
);
export const deletePhoneSuccess = createAction(DELETE_PHONE_SUCCESS, (id) => ({
  id,
}));
export const setLoadingIndicator = createAction(
  SET_LOADING_INDICATOR,
  (loading) => ({ loading })
);
export const setPhoneLoadingIndicator = createAction(
  SET_PHONE_LOADING_INDICATOR,
  (loading) => ({ loading })
);
export const setAddressLoadingIndicator = createAction(
  SET_ADDRESS_LOADING_INDICATOR,
  (loading) => ({ loading })
);
export const setSelectedOrder = createAction(SET_SELECTED_ORDER, (id) => ({
  id,
}));
export const resetUser = createAction(RESET_USER);

export const getUser = () => {
  return async function (dispatch, getState, { createAuthClient }) {
    if (tokenUtil.getToken()) {
      try {
        const response = await createAuthClient().get("/user/me");

        dispatch(getUserSuccess(response.data));
        dispatch(setLoadingIndicator(false));
      } catch (err) {
        dispatch(setLoadingIndicator(false));
      }
    } else {
      dispatch(setLoadingIndicator(false));
    }
  };
};

export const logoutUser = () => {
  return async function (dispatch) {
    dispatch(resetUser());
    dispatch(resetBrands());
    dispatch(resetOrder());
  };
};

export const getPhoneNumbers = () => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    if (user) {
      dispatch(setPhoneLoadingIndicator(true));
      try {
        const response = await createAuthClient().get(
          `/users/${user.id}/phonenumbers`
        );

        dispatch(getPhonesSuccess(response.data));
        dispatch(setPhoneLoadingIndicator(false));
      } catch (err) {
        dispatch(setPhoneLoadingIndicator(false));
      }
    }
  };
};

export const addPhone = (phone) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    try {
      const response = await createAuthClient().post(
        `/users/${user.id}/phonenumbers`,
        phone
      );

      dispatch(addPhoneSuccess(response.data));
    } catch (err) {
      throw new Error(
        err.response.data ? err.response.data.message : GENERAL_ERROR
      );
    }
  };
};

export const updatePhone = (phone) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    try {
      const response = await createAuthClient().put(
        `/users/${user.id}/phonenumbers/${phone.id}`,
        phone
      );

      dispatch(updatePhoneSuccess(response.data));
    } catch (err) {
      throw new Error(
        err.response.data ? err.response.data.message : GENERAL_ERROR
      );
    }
  };
};

export const deletePhone = (id) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    try {
      await createAuthClient().delete(`/users/${user.id}/phonenumbers/${id}`);

      dispatch(deletePhoneSuccess(id));
    } catch (err) {
      throw new Error(
        err.response.data ? err.response.data.message : GENERAL_ERROR
      );
    }
  };
};

export const getAddresses = () => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    if (user) {
      dispatch(setAddressLoadingIndicator(true));
      try {
        const response = await createAuthClient().get(
          `/users/${user.id}/addresses`
        );

        dispatch(getAddressesSuccess(response.data));
        dispatch(setAddressLoadingIndicator(false));
      } catch (err) {
        dispatch(setAddressLoadingIndicator(false));
      }
    }
  };
};

export const addAddress = (address) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    try {
      const response = await createAuthClient().post(
        `/users/${user.id}/addresses`,
        address
      );

      dispatch(addAddressSuccess(response.data));
    } catch (err) {
      throw new Error(
        err.response.data ? err.response.data.message : GENERAL_ERROR
      );
    }
  };
};

export const updateAddress = (address) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    try {
      const response = await createAuthClient().put(
        `/users/${user.id}/addresses/${address.id}`,
        address
      );

      dispatch(updateAddressSuccess(response.data));
    } catch (err) {
      throw new Error(
        err.response.data ? err.response.data.message : GENERAL_ERROR
      );
    }
  };
};

export const deleteAddress = (id) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    try {
      await createAuthClient().delete(`/users/${user.id}/addresses/${id}`);

      dispatch(deleteAddressSuccess(id));
    } catch (err) {
      throw new Error(
        err.response.data ? err.response.data.message : GENERAL_ERROR
      );
    }
  };
};

export const getOrders = () => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    try {
      const response = await createAuthClient().get(`/users/${user.id}/orders`);

      dispatch(getOrdersSuccess(response.data));
    } catch (err) {
      throw new Error(
        err.response.data ? err.response.data.message : GENERAL_ERROR
      );
    }
  };
};

export default reducer;
