import React, { useState } from 'react';
import {
  makeStyles,
  Select,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { COUNTIES, isAdmin, replaceAt } from 'util/APIUtils';
import { deletePhone as deletePhoneAction } from 'modules/brand';
import Phones from 'components/Phones';
import Emails from 'components/Emails';
import Address from 'components/Address';
import { PHONE_TYPES, USA_STATES } from 'common/constants';

const _notEmpty = array => array && array.length > 0;

const useStyles = makeStyles(() => ({
  step2: {
    margin: '0 auto',
    maxWidth: 260,
  },
  phoneText: {
    display: 'block',
    marginBottom: 10,
  },
  email: {
    width: 250,
    marginBottom: 12,
  },
  phone: {
    width: 160,
    marginBottom: 12,
  },
  phoneType: {
    width: 80,
    margin: '3px 8px 9px 0',
  },
  addressLine: {
    display: 'block',
    width: 250,
    marginBottom: 12,
  },
  addressCity: {
    width: 152,
    display: 'inline-block',
    marginBottom: 12,
  },
  addressState: {
    maxWidth: 100,
    display: 'inline-block',
    marginBottom: 12,
  },
  addressZip: {
    maxWidth: 120,
    display: 'inline-block',
    marginBottom: 12,
  },
  addressCounty: {
    width: 130,
    marginBottom: 12,
  },
  saveButton: {
    padding: '1em 0',
  },
  editButton: {
    margin: '.5em 0'
  },
}));

export default function ContactInformation({
  user,
  owner,
  handleOwnerChange,
  displayEmail,
  displayEditButton,
  saveOwner,
  brandId,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deletePhone = (...args) => dispatch(deletePhoneAction(...args));
  const [edit, setEdit] = useState(false);
  const { businessName, firstName, lastName, phones, emails, address } = owner;
  const { lineOne, lineTwo, city, zipcode, state, county } = address || { lineOne: '', lineTwo: '', city: '', zipcode: '', state: 'UT', county: 'Utah' };
  const admin = isAdmin(user);

  const handleChange = (updatedProps) => {
    const newOwner = Object.assign({}, owner, updatedProps);

    handleOwnerChange(newOwner);
  };

  const handleAddressChange = (updatedAddressProps) => {
    const newAddress = Object.assign({}, owner.address, updatedAddressProps);

    handleChange({ address: newAddress });
  };

  const addToPhoneArray = (updatedPhone) => {
    const newPhones = _notEmpty(owner.phones) ? replaceAt(owner.phones, updatedPhone) : [updatedPhone];

    handleChange({ phones: newPhones });
  };

  const removeToPhoneArray = (phoneId) => {
    const phones = owner.phones || [];

    handleChange({ phones: phones.filter((p) => p.id !== phoneId) });
  };

  const addToEmailArray = (updatedEmail) => {
    const newEmails = _notEmpty(owner.emails) ? replaceAt(owner.emails, updatedEmail) : [updatedEmail];

    handleChange({ emails: newEmails });
  };

  const save = () => {
    if (saveOwner) {
      saveOwner();
    }
    setEdit(!edit);
  };

  const saveOnEnter = (event) => {
    if (event.key === 'Enter') {
      save();
    }
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <div className={classes.step2}>
      {displayEditButton && edit && (
        <SaveButtons classes={classes} save={save} cancel={toggleEdit} />
      )}
      {!edit ?
        (
          <div>
            <Phones phones={phones} />
            {displayEmail && <Emails emails={emails} />}
            <Address address={address} />
          </div>
        ) : (
          <div>
            <form className={classes.root} noValidate autoComplete="off" onKeyUp={saveOnEnter}>
              {user && isAdmin(user) && (
                <div>
                  <Edit id="businessName" label="New Business Name" value={businessName} className={classes.addressLine} handleChange={handleChange} />
                  <Edit id="firstName" label="New First Name" value={firstName} className={classes.addressLine} handleChange={handleChange} />
                  <Edit id="lastName" label="New Last Name" value={lastName} className={classes.addressLine} handleChange={handleChange} />
                </div>
              )}
              {_notEmpty(phones) ?
                (
                  phones.map(phone => {
                    return (
                      <PhoneTextField
                        key={phone.id ? phone.id : 'new'}
                        phone={phone}
                        addToPhoneArray={addToPhoneArray}
                        classes={classes}
                        admin={admin}
                        brandId={brandId}
                        ownerId={owner.id}
                        deletePhone={deletePhone}
                        removeToPhoneArray={removeToPhoneArray}
                      />
                    );
                  })
                ) :
                (
                  <PhoneTextField
                    addToPhoneArray={addToPhoneArray}
                    classes={classes}
                    admin={admin}
                    brandId={brandId}
                    ownerId={owner.id}
                  />
                )
              }
              {emails && emails.map(email => {
                return (
                  <EmailEdit
                    key={email.id ? email.id : 'new'}
                    email={email}
                    className={classes.email}
                    addToEmailArray={addToEmailArray}
                  />
                );
              })}
              <Edit id="lineOne" label="line one" value={lineOne} className={classes.addressLine} handleChange={handleAddressChange} />
              <Edit id="lineTwo" label="line two" value={lineTwo} className={classes.addressLine} handleChange={handleAddressChange} />
              <Edit id="city" label="city" value={city} className={classes.addressCity} handleChange={handleAddressChange} />&nbsp;
              <Select
                native
                className={classes.addressState}
                id='state'
                value={state}
                onChange={(e) => handleAddressChange({ state: e.target.value })}
              >
                <option aria-label="None" value="" />
                {Object.entries(USA_STATES).map(([abbr, name]) =>
                  <option key={abbr} value={abbr}>{name}</option>
                )}
              </Select><br />
              <Edit id="zipcode" label="zipcode" value={zipcode} className={classes.addressZip} handleChange={handleAddressChange} />&nbsp;
              <Select
                native
                className={classes.addressCounty}
                id='county'
                value={county}
                onChange={(e) => handleAddressChange({ county: e.target.value })}
              >
                <option aria-label="None" value="" />
                {COUNTIES.map((county) =>
                  <option key={county} value={county}>{county}</option>
                )}
              </Select>
            </form>
          </div>
        )
      }
      {displayEditButton && edit && <SaveButtons classes={classes} save={save} cancel={toggleEdit} />}
      {displayEditButton && !edit && (
        <Button
          onClick={toggleEdit}
          size="small"
          variant='contained'
          color='secondary'
          className={classes.editButton}
        >
          Edit &nbsp;
          <EditIcon fontSize="small" />
        </Button>
      )}
    </div>
  );
};

function SaveButtons({ classes, save, cancel }) {
  return <div className={classes.saveButton}>
    <Button onClick={cancel} size="small" variant='contained' color='default'>
      Cancel
    </Button>
    &nbsp;
    <Button onClick={save} size="small" variant='contained' color='secondary'>
      Save &nbsp;
      <SaveIcon fontSize="small" />
    </Button>
  </div>
};

function Edit({ id, label, value, className, handleChange }) {
  const updateValue = (e) => {
    const newObject = {};
    newObject[id] = e.target.value;
    handleChange(newObject);
  };

  return (
    <TextField
      id={id}
      label={label}
      variant="outlined"
      value={value ? value : ''}
      className={className}
      size="small"
      onChange={updateValue}
    />
  );
};

function EmailEdit({ email, className, addToEmailArray }) {
  const handleEmailChange = (e) => {
    const oldEmail = email ? email : { email: '' };
    const updatedEmail = Object.assign({}, oldEmail, { email: e.target.value });
    addToEmailArray(updatedEmail);
  };

  return (
    <>
      <TextField
        id={'emailEmail_' + (email && email.id)}
        label="email"
        value={email.email}
        className={className}
        onChange={handleEmailChange}
        variant="outlined" size="small"
      />
      <br />
    </>
  );
};

function PhoneTextField({
  phone,
  addToPhoneArray,
  classes,
  admin,
  brandId,
  ownerId,
  deletePhone,
  removeToPhoneArray,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const hasPhone = phone && phone.id;

  const handleNumberChange = (e) => {
    const oldPhone = phone ? phone : { type: PHONE_TYPES.MOBILE };
    const updatedPhone = Object.assign({}, oldPhone, { number: e.target.value });

    addToPhoneArray(updatedPhone);
  };

  const handleTypeChange = (e) => {
    const oldPhone = phone ? phone : { number: '' };
    const updatedPhone = Object.assign({}, oldPhone, { type: e.target.value });

    addToPhoneArray(updatedPhone);
  };

  const handleDelete = () => {
    deletePhone(brandId, ownerId, phone.id)
      .then((response) => {
        removeToPhoneArray(phone.id);
        enqueueSnackbar(response.message, { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <Select
        native
        className={classes.phoneType}
        id={'phoneType_' + (phone && phone.id)}
        value={(phone && phone.type)}
        onChange={handleTypeChange}
      >
        <option aria-label="None" value="" />
        {Object.entries(PHONE_TYPES).map(([key, type]) =>
          <option key={key} value={type}>{type}</option>
        )}
      </Select>
      <TextField
        id={'phoneNumber_' + (phone && phone.id)}
        label="phone number"
        value={phone && phone.number ? phone.number : ''}
        onChange={handleNumberChange}
        className={classes.phone}
        variant="outlined" size="small"
        style={{ marginBottom: '0', marginRight: '5px' }}
      />
      {admin && hasPhone && (
        <IconButton onClick={handleDelete} size="small">
          <DeleteIcon style={{ fontSize: '18px' }} />
        </IconButton>
      )}
    </div>
  );
};
