import "./App.css";

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import clsx from "clsx";

import { getUser } from "modules/user";
import { getCart } from "modules/cart";
import { setRegistrationSession } from "containers/brandRegistration/util";

// Common
import LoadingIndicator from "components/LoadingIndicator";
import PrivateRoute from "components/PrivateRoute";
import LazyComponent from "components/lazyComponent";

// Pages
import BrandSearch from "containers/brandSearch";
import NotFound from "components/NotFound";
import Navigation from "components/Navigation";
import CookiePopup from "components/cookiePopup";
import Privacy from "components/Privacy";

import { selectAppPending } from "common/selectors";
import { selectUser } from "selectors/user";

// Admin services --BEGIN
import { getInspectors } from "modules/admininspectors";
import {
  selectIsUserInspector,
  selectIsUserAdminEdit,
  selectIsUserAdmin,
} from "selectors/user";
// Admin services --END

// BEGIN -- Inspectors Contact Link
import { getInspectorsContactLink } from "modules/inspectorsContactLink";
//  END -- Inspectors Contact Linl
const MyBrands = LazyComponent(import("containers/myBrands"));
const Checkout = LazyComponent(import("containers/checkout"));
const BrandRegistration = LazyComponent(import("containers/brandRegistration"));
const Help = LazyComponent(import("containers/help"));
const Profile = LazyComponent(import("containers/profile"));
const Certificate = LazyComponent(import("containers/certificate"));
const WalletCard = LazyComponent(import("containers/walletCard"));
const Logout = LazyComponent(import("containers/logout"));
const ConfirmReset = LazyComponent(import("containers/confirmReset"));
const CanceledCheckout = LazyComponent(import("containers/canceledCheckout"));
const CheckoutComplete = LazyComponent(import("containers/checkoutCompleted"));
const Brand = LazyComponent(import("containers/brand"));
const Renew = LazyComponent(import("containers/renew"));
const PrivacyPolicy = LazyComponent(import("containers/privacyPolicy"));
const TermsOfUse = LazyComponent(import("containers/termsOfUse"));
const Accessibility = LazyComponent(import("containers/accessibility"));
const BrandCollaborator = LazyComponent(import("containers/brandCollaborator"));
const ProfileCertificate = LazyComponent(
  import("containers/profileCertificate")
);
const HorseTravelPermit = LazyComponent(import("containers/horseTravelPermit"));

// Begin - Admin services
const AdminInspections = LazyComponent(import("containers/adminInspections"));
// End - Admin services
const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAppPending);
  const user = useSelector(selectUser);
  const location = useLocation();
  const userId = user ? user.id : null;
  const isAuth = location.pathname.startsWith("/auth");
  const inMaintenanceMode = false;

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch, userId]);

  // BEGIN -- Admin services
  useEffect(() => {
    dispatch(getInspectors());
  }, [dispatch]);

  const isUserInspector = useSelector(selectIsUserInspector);
  const isUserAdminEdit = useSelector(selectIsUserAdminEdit);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  // END -- Admin services

  // BEGIN - Inspector Contact link
  useEffect(() => {
    dispatch(getInspectorsContactLink());
  }, [dispatch]);

  // END -- Inspector Contact link

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="app">
      <Navigation inMaintenanceMode={inMaintenanceMode} />
      <div className={clsx({ "app-body": !isAuth })}>
        {inMaintenanceMode && (
          <p style={{ textAlign: "center", padding: "40px 20px 0" }}>
            The site is temporarily down for maintenance. We expect to have the
            site back on line very soon.
          </p>
        )}
        {!inMaintenanceMode && (
          <Switch>
            <Redirect exact from="/brands/search" to="/" />

            {(isUserInspector || isUserAdmin || isUserAdminEdit) && (
              <PrivateRoute
                exact
                path={"/adminInspections"}
                component={AdminInspections}
              />
            )}
            <PrivateRoute
              exact
              path={[
                "/brands/new",
                "/brands/new/:step(positions|owners|address)",
              ]}
              component={BrandRegistration}
            />
            <PrivateRoute exact path="/brands/:id" component={Brand} />
            <PrivateRoute
              exact
              path="/brand/collaborator"
              component={BrandCollaborator}
            />
            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute
              exact
              path={["/profile", "/profile/:type(brands|orders|certificates)"]}
              component={Profile}
            />
            <PrivateRoute
              exact
              path="/profile/certificates/:id"
              component={ProfileCertificate}
            />
            <PrivateRoute
              exact
              path="/profile/travel-permits/:id"
              component={HorseTravelPermit}
            />

            <Route exact path="/" component={BrandSearch} />
            <Route exact path="/my-brands" component={MyBrands} />
            <Route exact path="/cart" component={Checkout} />
            <Route
              exact
              path="/brands/registration/:id"
              render={({ match, location }) => {
                const {
                  params: { id },
                } = match;

                setRegistrationSession(id);

                return (
                  <Redirect
                    to={{ pathname: "/brands/new", state: { from: location } }}
                  />
                );
              }}
            />
            <Route
              exact
              path="/certificates/:lookupCode"
              component={Certificate}
            />
            <Route exact path="/cards/:lookupCode" component={WalletCard} />
            <Route exact path="/confirmreset" component={ConfirmReset} />
            <Route
              exact
              path="/my/brands/renewal/cancel"
              component={CanceledCheckout}
            />
            <Route
              exact
              path="/my/brands/renewal/complete"
              component={CheckoutComplete}
            />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/accessibility" component={Accessibility} />
            <Route
              exact
              path={[
                "/help",
                "/help/:type(brandRecorder|department|brandInspectors)",
              ]}
              component={Help}
            />
            <Route path="/:brandHash/:pinHash" component={Renew} />
            <Route
              exact
              path="/feedback"
              component={() => {
                window.location.href =
                  "https://www.surveymonkey.com/r/BrandRenewPrototypeFeedback";
                return null;
              }}
            />
            <Route component={NotFound} />
          </Switch>
        )}
        {!isAuth && <Privacy />}
      </div>
      <CookiePopup />
    </div>
  );
};

export default App;
