import { createSelector } from 'reselect';

const selectCartDomain = (state) => state.get('cart');

export const selectCartIsLoading = createSelector(
  selectCartDomain,
  (state) => state.getIn(['ui', 'loading']),
);

export const selectCart = createSelector(
  selectCartDomain,
  (state) => state.getIn(['data', 'cart']).toJS(),
);

export const selectOrder = createSelector(
  selectCartDomain,
  (state) => state.getIn(['data', 'order']).toJS(),
);

export const selectCartError = createSelector(
  selectCartDomain,
  (state) => state.getIn(['ui', 'error']),
);
