import React, { useState, useEffect } from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    left: '0',
    bottom: '0',
    right: '0',
    backgroundColor: '#ffffff',
    padding: '20px',
    boxShadow: '0px -1px 5px 0px rgba(0,0,0,0.35)',
  },
  content: {
    padding: '0 20px',
    maxWidth: '1100px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flex: '1',
      paddingRight: '30px',
    },
    '& h3': {
      margin: '0 0 10px',
    },
    '& p': {
      margin: '0',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0',
      '& > div': {
        paddingRight: '0',
        marginBottom: '20px',
      },
    },
  },
}));

const COOKIE_KEY = 'udaf-cookie-accepted';

const CookiePopup = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const handleAccept = () => {
    window.localStorage.setItem(COOKIE_KEY, 'true');
    setVisible(false);
  };

  useEffect(() => {
    if (!window.localStorage.getItem(COOKIE_KEY)) {
      setVisible(true);
    }
  }, []);

  if (visible) {
    return (
      <div className={classes.wrapper} data-id="B9aPp">
        <div className={classes.content}>
          <div>
            <h3>Cookies on brands.utah.gov</h3>
            <p>We use cookies on our website. You are free to manage this via your browser settings any time. <a href="https://www.utah.gov/privacypolicy.html" target="privacy">Learn more, Including how to manage your privacy settings.</a></p>
          </div>
          <Button color="primary" variant="contained" onClick={handleAccept} data-id="dgJAx">
            Accept
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

export default CookiePopup;
