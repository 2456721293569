import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
    container: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100px',
        minWidth: '100px'
    },
}));

export default function LoadingIndicator() {
    const classes = useStyles();
    return <div className={classes.container} >
        <CircularProgress />
    </div>
}