import React, { useEffect, useState, useCallback, memo } from 'react';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';

import {
  clearCachedBrandsFor,
  FORCE_REFRESH,
  getLettersFromDrawing,
} from 'util/APIUtils';
import useDebounce from 'util/useDebounce';
import {
  getBrands,
  getBrandsByLetters,
  getMyBrands,
  resetBrands
} from 'modules/brand';
import { selectBrandResultsLength } from 'selectors/brand';
import News from 'components/News';
import Footer from 'components/Footer';
import BrandResults from './components/BrandResults';
import SearchPagination from './components/SearchPagination';
import Search from './components/Search';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  searchFieldRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid grey',
    width: 600,
    height: 50,
  },
  searchLoader: {
    width: '22px !important',
    height: '22px !important',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  title: {
    paddingTop: '1em'
  },
  iconButton: {
    padding: 10,
  },
  instructions: {
    padding: 0,
    textAlign: 'left'
  },
  searchWrapper: {
    position: 'relative',
    marginBottom: '20px',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
}));

const BrandSearch = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const brandResultsLength = useSelector(selectBrandResultsLength);
  const { totalElements, totalPages } = brandResultsLength;
  const { enqueueSnackbar } = useSnackbar();
  const { location: { pathname, search: querystringFromUrl } } = history;

  const brandsPerPage = 10;
  const queryParams = queryString.parse(querystringFromUrl);
  const pageFromQuery = parseInt(queryParams.page, 10);
  const offsetDefault = queryParams.page ? ((pageFromQuery - 1) * brandsPerPage) / brandsPerPage : 0;
  const currentPageDefault = queryParams.page ? pageFromQuery : 1;

  const [image, setImage] = useState(null);

  const [query, setQuery] = useState(queryParams.query || '');
  const [isSearching, setIsSearching] = useState(false);
  const [attempted, setAttempted] = useState(false);
  const [requestedPageOffset, setRequestedPageOffset] = useState(offsetDefault);
  const [currentPage, setCurrentPage] = useState(currentPageDefault);

  const debouncedSearchTerm = useDebounce(query, 500);
  const hasResults = totalPages > 0;

  const reloadBrandsOnChange = (brandId) => {
    clearCachedBrandsFor(brandId);
    searchBrand();
    dispatch(getMyBrands(FORCE_REFRESH));
  };

  const searchByImage = useCallback(async () => {
    // Temporary fix: must not trigger change if search by string enabled
    if (!debouncedSearchTerm) {
      if (image) {
        setIsSearching(true);
        setAttempted(true);
        await getLettersFromDrawing(image)
        .then(response => {
          const returnedLetters =
            response.data &&
            response.data.data &&
            response.data.data.text &&
            response.data.data.text.description &&
            response.data.data.text.description.length > 0 &&
            response.data.data.text.description[0];

          dispatch(
            getBrandsByLetters(
              returnedLetters,
              requestedPageOffset,
              () => {
                setIsSearching(false);
              },
              (error) => {
                if (error) {
                  setIsSearching(false);
                  enqueueSnackbar(
                    error.message || 'Oops! Something went wrong. Please try again!',
                    {variant: 'error'},
                  );
                }
              },
            ),
          );
        });
      } else {
        setAttempted(false);
        setRequestedPageOffset(0);
        setCurrentPage(1);
        dispatch(resetBrands());
      }
    }
  }, [image, requestedPageOffset, enqueueSnackbar, dispatch, debouncedSearchTerm]);

  const searchBrand = useCallback(async () => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      setAttempted(true);

      await dispatch(
        getBrands(
          debouncedSearchTerm,
          requestedPageOffset,
          (error) => {
            if (error) {
              enqueueSnackbar(
                error.message || 'Oops! Something went wrong. Please try again!',
                { variant: 'error' },
              );
            }
          },
        ),
      );

      setIsSearching(false);
    } else {
      setAttempted(false);
      setRequestedPageOffset(0);
      setCurrentPage(1);
      dispatch(resetBrands());
    }
  }, [debouncedSearchTerm, requestedPageOffset, enqueueSnackbar, dispatch]);

  const handleSearchFieldUpdates = str => {
    setQuery(str);
  };

  const setOffset = (offset) => {
    setRequestedPageOffset(offset / brandsPerPage);
  };

  const handlePageChange = (ev, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * brandsPerPage)
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      const { location: { hash } } = history;
      const search = `?${queryString.stringify({ query: debouncedSearchTerm, page: currentPage })}`;

      history.push({ pathname, search, hash });
    } else {
      history.push({ pathname });
    }
  }, [debouncedSearchTerm, history, pathname, currentPage]);

  useEffect(() => {
    searchBrand();
  }, [debouncedSearchTerm, requestedPageOffset, enqueueSnackbar, searchBrand]);

  useEffect(() => {
    searchByImage();
  }, [searchByImage, requestedPageOffset, enqueueSnackbar, searchBrand]);

  return (
    <div className="page text-center">
      {!attempted && <News />}
      <Search
        isSearching={isSearching}
        setIsSearching={setIsSearching}
        query={query}
        handleSearchFieldUpdates={handleSearchFieldUpdates}
        handleImageSearch={setImage}
      />
      <div className="brands">
        {attempted && totalPages === 0 && !isSearching ?
          (
            <Typography variant="subtitle1" color="textSecondary">
              - no brands found -
            </Typography>
          ) : (
            <div>
              {hasResults && (
                <SearchPagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  rowPerPage={brandsPerPage}
                  total={totalElements}
                />
              )}
              <div className={classes.searchWrapper}>
                <BrandResults reloadDisplayedBrands={reloadBrandsOnChange} />
                {isSearching && hasResults && (
                  <div className={classes.loadingOverlay} />
                )}
              </div>
              {hasResults && (
                <SearchPagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  rowPerPage={brandsPerPage}
                  total={totalElements}
                />
              )}
            </div>
          )
        }
      </div>
      <Footer />
    </div>
  );
}

export default memo(BrandSearch);
