import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { selectCart } from 'selectors/cart';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: '30px',
    display: 'inline',
  },
  count: {
    display: 'inline-block',
    color: '#ffffff',
    fontWeight: '800',
    fontSize: '11px',
    height: '19px',
    width: '19px',
    borderRadius: '19px',
    lineHeight: '19px',
    textAlign: 'center',
    backgroundColor: theme.custom.danger,
  },
}));

const Cart = () => {
  const classes = useStyles();
  const cart = useSelector(selectCart);

  return (
    <span className={classes.wrapper}>
      <ShoppingCartIcon className={classes.icon} />
      {cart.length > 0 && <span className={classes.count}>{cart.length}</span>}
    </span>
  );
};

export default Cart;
