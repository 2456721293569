import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'selectors/user';

export default function PrivateRoute(props) {
  const { component: Component, currentUser, ...rest } = props;
  const user = useSelector(selectUser);

  return (
    <Route {...rest}>
      {user ?
        <Component {...rest} {...props} />
        : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      }
    </Route>
  );
}
